import React, { PureComponent } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
// import dummyData from '../components/Finance/dummyData'

import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Typography } from '@mui/material';



function getRandomDateIn2023() {
  const start = new Date('2023-01-01').getTime();
  const end = new Date('2023-12-31').getTime();
  const randomTime = start + Math.random() * (end - start);
  return new Date(randomTime).toISOString();
}

function getRandomPrice() {
  return Math.floor(Math.random() * 10000) + 1000; // Random price between 1000 and 10999
}

const newDataset = [];


for (let i = 0; i < 200; i++) {
  const newItem = {
    amount: getRandomPrice(),
    updatedAt: getRandomDateIn2023(),
  };
  newDataset.push(newItem);
}

const yearlyTarget = 100000;

// Initialize an array to store the monthly totals
const monthlyTotals = [
  { name: 'Jan', uv: 0, target: yearlyTarget, amt: 0 },
  { name: 'Feb', uv: 0, target: yearlyTarget, amt: 0 },
  { name: 'Mar', uv: 0, target: yearlyTarget, amt: 0 },
  { name: 'Apr', uv: 0, target: yearlyTarget, amt: 0 },
  { name: 'May', uv: 0, target: yearlyTarget, amt: 0 },
  { name: 'Jun', uv: 0, target: yearlyTarget, amt: 0 },
  { name: 'Jul', uv: 0, target: yearlyTarget, amt: 0 },
  { name: 'Aug', uv: 0, target: yearlyTarget, amt: 0 },
  { name: 'Sep', uv: 0, target: yearlyTarget, amt: 0 },
  { name: 'Oct', uv: 0, target: yearlyTarget, amt: 0 },
  { name: 'Nov', uv: 0, target: yearlyTarget, amt: 0 },
  { name: 'Dec', uv: 0, target: yearlyTarget, amt: 0 },
];

// Iterate through the newDataset and calculate monthly totals
newDataset.forEach(item => {
  const updatedAt = new Date(item.updatedAt);
  const month = updatedAt.getMonth(); // 0-based index
  monthlyTotals[month].amt += item.amount;
  monthlyTotals[month].uv += item.amount * 0.7; // Example value for 'uv'
  monthlyTotals[month].pv += item.amount * 0.34; // Example value for 'pv'
});




export default class FinanceStatistics extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/composed-chart-of-same-data-i67zd';


  render() {


    return (
      <>
        <div className="d-flex  pt-2 justify-content-between">
          <Typography>Yearly Perfomance Data</Typography>

          <div className="d-flex">
            <Box sx={{ minWidth: 120 }} >
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Facility
                </InputLabel>
                <NativeSelect
                  defaultValue={30}
                  inputProps={{
                    name: 'age',
                    id: 'uncontrolled-native',
                  }}
                >
                  <option value={1}>All</option>
                  <option value={1}>Rooms</option>
                  <option value={2}>Conference</option>

                </NativeSelect>
              </FormControl>
            </Box>

            <Box sx={{ minWidth: 120 }} className='ms-4'>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Year
                </InputLabel>
                <NativeSelect
                  defaultValue={30}
                  inputProps={{
                    name: 'age',
                    id: 'uncontrolled-native',
                  }}

                >
                  <option value={1} >2021</option>
                  <option value={1} >2022</option>
                  <option value={1} >2023</option>
                  <option value={2} >2024</option>
                  <option value={3} >2025</option>
                  <option value={4} >2026</option>
                  <option value={5}>2027</option>
                </NativeSelect>
              </FormControl>
            </Box>
          </div>
        </div>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={500}
            height={450}
            data={monthlyTotals}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" scale="band" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="amt" barSize={20} fill="#413ea0" name="Revenue" />
            <Line type="monotone" dataKey="target" stroke="#ff7300" name="Target" />
          </ComposedChart>


        </ResponsiveContainer>

      </>
    );
  }
}
