import React from 'react'
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { Rating } from '@mui/material';
import CurrencyFormat from 'react-currency-format';

const ExpenseData = (props) => {


  const columns = [
    {
      name: 'Product/Service',
      selector: row => row.product,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => <CurrencyFormat value={row.amount} displayType={'text'} thousandSeparator={true} prefix={'Ksh '} renderText={value => <div>{value}</div>} />,
    },
    {
      name: 'Category',
      selector: row => row.category,
    }, {
      name: 'Transaction Date ',
      selector: row => moment(row.date).format('lll'),
      sortable: true,
    },
    {
      name: 'Description',
      selector: row => row.description,
    },


  ];


  return (
    <div>
      <DataTable
        columns={columns}
        data={props.data}
        pagination
        highlightOnHover
        pointerOnHover
        // onRowClicked={(row, event) => {
        //   setRowId(row.id)
        //   setShowCommentDetails(true)
        // }}
        customStyles={{
          headRow: {
            style: {
              background: 'rgb(255, 215, 0,0.5)',
              color: "dark"
            },
          },
          pagination: {
            style: {
              background: 'rgb(255, 215, 0,0.3)',
              color: "dark"
            },
          },
        }}
      />
    </div>
  )
}

export default ExpenseData