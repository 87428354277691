import React from "react";
import { Chart } from "react-google-charts";

const BarChart = ({ chartData, chartOptions }) => {
  return (
    <Chart
      chartType="Bar"
      width="100%"
      height="400px"
      data={chartData}
      options={chartOptions}
    />
  );
};

const MonthlyExpense = () => {
  const monthlyData = [
    ["Month", "Sales", "Expenses"],
    ["January", 1000, 400],
    ["February", 1170, 460],
    ["March", 660, 1120],
    ["April", 1030, 540],
    ["May", 1200, 600],
    ["June", 800, 300],
    ["July", 950, 400],
    ["August", 1100, 500],
    ["September", 1300, 600],
    ["October", 1000, 400],
    ["November", 1150, 500],
    ["December", 1350, 700],
  ];

  const options = {
    chart: {
      title: "Company Performance",
      subtitle: "Monthly Sales and Expenses",
    },
  };

  return (
    <div>
      <BarChart chartData={monthlyData} chartOptions={options} />
    </div>
  );
};

export default MonthlyExpense;
