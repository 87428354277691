import React from 'react'
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { Rating } from '@mui/material';
import CommentDetails from '../Details/commentDetails';

function Transactions(props) {
  const [showCommentDetails, setShowCommentDetails] = React.useState(false);
  const [rowId, setRowId] = React.useState();

  const columns = [
    {
      name: 'FullName',
      selector: row => row.fullName,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => row.amount,
    },
    {
      name: 'Facility',
      selector: row => row.facility,
    }, {
      name: 'Mode',
      selector: row => row.paymentMode,
    },
    {
      name: 'Updated On ',
      selector: row => moment(row.updatedAt).fromNow(),
      sortable: true,
    }
  ];


  return (
    <div>
      <DataTable
        columns={columns}
        data={props.data}
        pagination
        highlightOnHover
        pointerOnHover
        onRowClicked={(row, event) => {
          setRowId(row.id)
          setShowCommentDetails(true)
        }}
        customStyles={{
          headRow: {
            style: {
              background: 'rgb(255, 215, 0,0.5)',
              color: "dark"
            },
          },
          pagination: {
            style: {
              background: 'rgb(255, 215, 0,0.3)',
              color: "dark"
            },
          },
        }}
      />

      <CommentDetails
        // show={showCommentDetails}
        onHide={() => setShowCommentDetails(false)}
        rowId={rowId}
      />
    </div>

  )
}

export default Transactions