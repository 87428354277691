import { Alert, Button, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';

const EditPrices = (props) => {
  const [roomType, setRoomType] = useState("VIP")
  const [roomId, setRoomId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [roomDetails, setRoomDetails] = useState(null)
  const [categoryPrices, setCategoryPrices] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleCategoryPriceChange = (categoryId, value) => {
    setCategoryPrices((prevPrices) => ({
      ...prevPrices,
      [categoryId]: value,
    }));
  };

  const GetRoomById = (roomId) => {
    setIsLoading(true)
    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/RoomService/GetRoomById?id=${roomId}`)
      .then((response) => {
        setRoomDetails(response.data)
        setTimeout(() => {
          setRoomType(response.data.roomType)
          setRoomId(roomId)
          setIsLoading(false)// Set loading state to false after 3 seconds
        }, 1000);

        console.log(response.data);
      }).catch((e) => {
        console.log(e.response?.message)
      });
  }

  const UpdateRoomPrice = (newPrice, id) => {
    const payload = {
      id: id,
      categoryPrice: newPrice
    }
    axios
      .put(`https://api-dhejomel.azgard.co.ke/api/RoomService/UpdateRoomPrice`, payload,)
      .then((response) => {
        GetRoomById(roomId)
      }).catch((e) => {
        console.log(e.response?.message)
      });
  }


  React.useEffect(() => {
    GetRoomById(props.roomId)
  }, [props.show])

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
            Edit Room Price
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "500px" }}>
          <Typography variant='subtitle1' className='my-2'>Define Room Type eg. VIP</Typography>
          <TextField
            id="outlined-basic"
            label="Room Type eg. VIP"
            variant="outlined"
            className='w-100'
            value={roomType}
          />



          <>
            <Typography variant='subtitle1' className='mt-4'>Define Room Prices</Typography>
            {
              roomDetails?.category.$values?.map((item, key) =>
                <TextField
                  label={item?.categoryName}
                  id="outlined-start-adornment"
                  className='mt-3 w-100'
                  value={categoryPrices[item?.id] || item?.categoryPrice}
                  onChange={(e) =>
                    handleCategoryPriceChange(item?.id, e.target.value)
                  }
                  onBlur={(e) => UpdateRoomPrice(e.target.value, item?.id)}
                  color="success"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">KES</InputAdornment>,
                  }}
                />

              )
            }

          </>

        </Modal.Body>



        <Modal.Footer>

          <Button
            variant="contained"
            onClick={() => {
              props.onHide()
              props.getRooms()
            }}
          >Exit</Button>

        </Modal.Footer>
      </Modal>


    </div>
  )
}

export default EditPrices

