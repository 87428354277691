import axios from 'axios';
import React from 'react'
import { Modal } from 'react-bootstrap'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, TextField } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Swal from 'sweetalert2'
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';


const AddUser = (props) => {
  const [autoGen, setAutoGen] = React.useState(false);
  const [firstName, setFirstName] = React.useState();
  const [lastName, setLastName] = React.useState();
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();
  const [role, setRole] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const AddUser = () => {
    setIsLoading(true)

    const payload = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      password: password,
      role: role,
      isAutogen: autoGen
    }

    axios
      .post(`https://api-dhejomel.azgard.co.ke/api/Users/Register`, payload)
      .then((response) => {
        console.log(response.data);
        console.log('success Getting rooms');

        Swal.fire(
          'Success!',
          'User Added Successfully!',
          'success'
        )

        props.onHide()
        props.getUsers()
        setIsLoading(false)

      }).catch((e) => {
        console.log(e);
        setIsLoading(false)
        Swal.fire(
          'Ooops!',
          `${e.response.data}!`,
          'error'
        )
        console.log(e.response?.message)
      });
  }

  React.useEffect(() => {
    setIsLoading(false)
  }, [props.show])

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
            Add New User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <TextField
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className='mb-3 w-100 me-2'
            />

            <TextField
              id="outlined-basic"
              label="Last Name"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className='mb-3 w-100 ms-2'
            />
          </div>

          <TextField
            id="outlined-basic"
            label="Email Address"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className='mb-3 w-100'
          />

          {
            !autoGen && <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              disabled={autoGen}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className='mb-3 w-100'
            />
          }


          <FormGroup className='mb-3'>
            <FormControlLabel
              control={<Switch />}
              value={autoGen}
              onChange={() => {
                setAutoGen(!autoGen)
                setPassword('')
              }}
              label="Auto Generate Password"
            />
          </FormGroup>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={role}
              label="Role"
              onChange={handleChange}
            >
              <MenuItem value="standard">Staff Admin</MenuItem>
              <MenuItem value="SuperAdmin">Super Administrator</MenuItem>
            </Select>
          </FormControl>



        </Modal.Body>
        <Modal.Footer className='mt-5'>
          {
            isLoading ?
              <LoadingButton
                color="secondary"
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                disabled
              >
                <span>Processing..</span>
              </LoadingButton>
              :
              <Button
                variant="contained"
                onClick={() => AddUser()}
              >
                Submit
              </Button>
          }

        </Modal.Footer>
      </Modal></div>
  )
}

export default AddUser