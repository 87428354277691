import React from 'react'
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { Rating } from '@mui/material';
import CommentDetails from '../Details/commentDetails';

function CommentSetup(props) {
  const [showCommentDetails, setShowCommentDetails] = React.useState(false);
  const [rowId, setRowId] = React.useState();

  const columns = [
    {
      name: 'FullName',
      selector: row => row.fullName,
      sortable: true,
    },
    {
      name: 'Comments',
      selector: row => row.comments,
    },
    {
      name: 'Ratings',
      selector: row => <Rating name="read-only" value={row.rating} readOnly />,
    }, {
      name: 'Posted On ',
      selector: row => moment(row.updatedOn).fromNow(),
      sortable: true,
    }
  ];


  return (
    <div>
      <DataTable
        columns={columns}
        data={props.data}
        pagination
        highlightOnHover
        pointerOnHover
        onRowClicked={(row, event) => {
          setRowId(row.id)
          setShowCommentDetails(true)
        }}
        customStyles={{
          headRow: {
            style: {
              background: 'rgb(255, 215, 0,0.5)',
              color: "dark"
            },
          },
          pagination: {
            style: {
              background: 'rgb(255, 215, 0,0.3)',
              color: "dark"
            },
          },
        }}
      />

      <CommentDetails
        show={showCommentDetails}
        onHide={() => setShowCommentDetails(false)}
        rowId={rowId}
      />
    </div>

  )
}

export default CommentSetup