import { Button, Divider, TextField, Typography } from '@mui/material'
import React from 'react'
import { Col, NavLink, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast'
import axios from 'axios'
import mainImage from '../assets/images/main.png'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function Login() {
  const [isLogin, setIsLogin] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false)
  const [userEmail, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [firstName, setFirstName] = React.useState('')
  const [userId, setUserId] = React.useState('')
  const [newPass, setNewPass] = React.useState('')
  const [verificationCode, setVerficationCode] = React.useState('standard')
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const history = useHistory();


  const handleUpdate = () => {
    toast.loading('processing...')
    const user = {
      password: newPass,
      confirmPassword: newPass,
      userId: userId
    }

    return axios
      .post("https://api-dhejomel.azgard.co.ke/api/Users/ResetPassword",
        user
      )
      .then((response) => {
        toast.success(response.data)
        history.push("/staff");

      }).catch((e) => {
        toast.dismiss()
        toast.error(e.response?.data)
        console.log(e);
      });
  }

  const handleUserLogin = () => {
    toast.loading('processing...')

    setIsLoading(true)
    const user = {
      email: userEmail,
      password: password
    }
    return axios
      .post("https://api-dhejomel.azgard.co.ke/api/Users/Login",
        user
      )
      .then((response) => {
        var userDetails = {
          email: response.data.email,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          id: response.data.id,
          role: response.data.role
        }
        setFirstName(response.data.firstName)
        setUserId(response.data.id)
        localStorage.setItem("DhejomelToken", JSON.stringify(response.data.token));
        localStorage.setItem("DhejomelUser", JSON.stringify(userDetails));
        toast.dismiss(response.data.hasInitialPasswordChanged)
        if (response.data.hasInitialPasswordChanged === false) {
          setIsLogin(false)
        }
        else {
          history.push("/staff");
        }

      }).catch((e) => {
        toast.dismiss()
        toast.error(e.response?.data)
        console.log(e);
      });
  };



  return (

    <div className="d-flex m-0 p-0" style={{ height: '100vh', width: '100%' }} >
      <Toaster />
      <Row style={{ height: '100vh', width: '100%', margin: 0, padding: 0 }} >
        {
          isLogin ?
            <Col className="login-form  d-flex flex-column h-100 justify-content-center"
              style={{ background: 'white' }}
              md={6}>
              <h2 className="mx-5 mb-0">Dhejomel Hotel Admin </h2>
              <Typography variant='subtitle1' className="mx-5 my-2 text-muted mt-0 pt-0">Please enter your details to login</Typography>
              <TextField id="outlined-basic" label="email" variant="outlined" className='mx-5'
                onChange={(e) => setEmail(e.target.value)}

              />
              <FormControl variant="outlined" className='my-3 mx-5'>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>

              <Button
                variant="contained"
                className=" py-2 text-dark  mx-5"
                style={{ backgroundColor: "#FFD700" }}
                onClick={() => handleUserLogin()}
              >
                Login</Button>

              <Typography variant='body2' className="text-center mt-3">Dont't an account  <a href='/' > <strong className='text-primary'>Back Home</strong></a> </Typography>

            </Col>
            :
            <>

              <Col className="login-form  d-flex flex-column h-100 justify-content-center">
                <div className="mx-5">
                  <h2 className='mb-0'>Reset Password</h2>
                  <Typography variant='subtitle2' className='mb-2'>Hello, {firstName} Kindly set a new password</Typography>
                </div>
                <FormControl variant="outlined" className='my-3 mx-5'>
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => setNewPass(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <Button
                  variant="contained"
                  className=" py-2 text-dark  mx-5 bg-success text-white"
                  // style={{ backgroundColor: "#FFD700" }}
                  onClick={() => handleUpdate()}
                >Reset Password</Button>
              </Col>

            </>
        }
        <Col className=" m-0 p-0"
          style={{ backgroundColor: "rgb(128, 0, 0,0.9)" }}
          md={6}
        >
          <div className="marketing-image  d-flex flex-column h-100 justify-content-center px-4">
            <img src={mainImage}
              alt=""
              srcset=""
              className="img-fluid "
            />
            <Typography variant='h4' className="text-center text-warning ">Home Away From Home</Typography>
          </div>
        </Col>
      </Row>


    </div >

  )
}

export default Login
