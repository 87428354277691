import { Button, Rating, TextField } from '@mui/material';
import React from 'react'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Swal from 'sweetalert2'
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Executive from '../../assets/images/rooms/executive.jpeg'

const CommentDetails = (props) => {

  const [isLoading, setLoading] = React.useState(false);
  const [isFetching, setIsfetching] = React.useState(false);
  const [selectedComment, setSelectedComment] = React.useState();
  const [successMsg, setSuccessMsg] = React.useState();

  const success = () => {
    Swal.fire(
      'Success!',
      { successMsg },
      'success'
    )
  }


  const GetComment = (id) => {
    setIsfetching(true)

    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/Comments/GetCommentById?id=${id}`)
      .then((response) => {
        setIsfetching(false)
        setSelectedComment(response.data.$values)
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const HideComment = (id) => {
    setLoading(true)


    axios
      .put(`https://api-dhejomel.azgard.co.ke/api/Comments/HideComment?id=${id}`)
      .then((response) => {
        setLoading(false)
        setSuccessMsg("Comment Hidden Successfully!")
        success()
        props.onHide()
      })
      .catch((e) => {
        console.log(e);
      });
  };



  React.useEffect(() => {
    setLoading(false)
    GetComment(props.rowId)
  }, [props.show])



  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
            Comment Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            isFetching ? <>
              <div className="d-flex w-100">
                <p>loading..</p>
              </div>
            </> :
              <table className="table">
                <tbody>
                  <tr >
                    <td><strong>Full Name</strong></td>
                    <td colSpan={3}>{selectedComment?.fullName}</td>
                  </tr>
                  <tr >
                    <td><strong>Comment</strong></td>
                    <td colSpan={3}>{selectedComment?.comments}</td>
                  </tr>
                  <tr >
                    <td><strong>Ratings</strong></td>
                    <td colSpan={3}>
                      <Rating name="read-only" value={selectedComment?.rating} readOnly />
                    </td>
                  </tr>
                </tbody>
              </table>
          }

        </Modal.Body>
        <div className="container pb-3">
          <>
            {
              isLoading ?
                <div className="d-flex w-100 justify-content-end">
                  <LoadingButton
                    color="secondary"
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled
                  >
                    <span>Processing..</span>
                  </LoadingButton>
                </div>
                :
                <div className="d-flex w-100 justify-content-end">
                  {
                    selectedComment?.isEnabled ?
                      <Button
                        variant="contained"
                        className=' py-2 bg-danger'
                        onClick={() => HideComment(props?.rowId)}
                      >
                        Hide Comment
                      </Button>
                      :
                      <Button
                        variant="contained"
                        className=' py-2 '
                        disabled
                      >
                        Comment Hidden
                      </Button>


                  }


                </div>
            }
          </>
        </div>

      </Modal>
    </div>
  )
}

export default CommentDetails

//1 VIP
//10 Executive
//2 Superior

//No. of people expected
//special