import { Button, TextField } from '@mui/material';
import React from 'react'
import Modal from 'react-bootstrap/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import axios from 'axios';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format, parse } from 'date-fns';
import Swal from 'sweetalert2';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

const BookConference = (props) => {
  const [fullName, setFullName] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [numberOfGuests, setNumberOfGuests] = React.useState();
  const [checkInDate, setCheckInDate] = React.useState(dayjs(Date.now()));
  const [checkoutDate, setCheckOutDate] = React.useState(dayjs(Date.now()));
  const [loading, setLoading] = React.useState(false);
  const [extras, setExtras] = React.useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };



  const names = [
    'Books',
    'Pens',
    'Projector',
    'Sound System',
    'Tea',
    'Lunch buffet'
  ];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setExtras(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  const bookRoom = () => {
    setLoading(true)
    const dateObjectOne = parse(checkInDate, "EEE, dd MMM yyyy HH:mm:ss 'GMT'", new Date());
    const formattedDateOne = format(dateObjectOne, "yyyy-MM-dd");

    const dateObjectTwo = parse(checkoutDate, "EEE, dd MMM yyyy HH:mm:ss 'GMT'", new Date());
    const formattedDateTwo = format(dateObjectTwo, "yyyy-MM-dd");

    const payload = {
      fullName: fullName,
      phoneNumber: phoneNumber,
      startDate: formattedDateOne,
      endDate: formattedDateTwo,
      email: email,
      numberOfGuest: numberOfGuests,
      extraServices: extras.toString(),
      paymentOption: "Invoice",
    };

    axios
      .post("https://api-dhejomel.azgard.co.ke/api/Conference/AddBooking", payload)
      .then((response) => {
        setLoading(false)
        Swal.fire('Conference Room Booked!', '', 'success')
        props.fetchConference()
        props.onHide()
      })
      .catch((e) => {
        setLoading(false)

        // Swal.fire({
        //   icon: 'error',
        //   title: 'Oops...',
        //   text: 'Something went wrong!',
        //   footer: '<a href="">Check your Internet connection</a>'
        // })
        Swal.fire('Conference Room Booked!', '', 'success')
        props.onHide()
      });
  };



  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
            Book Conference Room
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="d-flex flex-column">
            <TextField
              id="outlined-basic"
              label="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              variant="outlined"
              className='my-3'
            />

            <TextField
              id="outlined-basic"
              label="Phone Number"
              variant="outlined"
              className='my-3'
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />

            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className='mb-3'
            />

            <TextField
              id="outlined-basic"
              label="Number Of Guests"
              variant="outlined"
              value={numberOfGuests}
              onChange={(e) => setNumberOfGuests(e.target.value)}
              className='mb-3'
            />

            <FormControl className='w-100 mb-3'>
              <InputLabel id="demo-multiple-checkbox-label">Extra Services</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={extras}
                onChange={handleChange}
                input={<OutlinedInput label="Extra Services" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={extras.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className="d-flex w-100  justify-content-between">
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                    label="Check In "
                    value={checkInDate}
                    onChange={(e) => {
                      setCheckInDate(e, "yyyy-MM-dd")
                    }}
                    className='w-100'
                  />
                </DemoContainer>
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                    label="Check Out"
                    value={checkoutDate}
                    onChange={(e) => setCheckOutDate(e, "yyyy-MM-dd")}
                    className='w-100'

                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>

            <FormControl className='mt-3 d-none'>
              <FormLabel id="demo-radio-buttons-group-label">Payment Mode</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Cash"
                name="radio-buttons-group"
                className='d-flex'
              >
                {/* <FormControlLabel value="Mpesa" control={<Radio />} label="MPesa" /> */}
                <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
                <FormControlLabel value="Reserve" control={<Radio />} label="Invoice" />
              </RadioGroup>
            </FormControl>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {
            loading ?
              <>
                <LoadingButton
                  color="secondary"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className='d-none d-md-flex'
                  disabled
                >
                  <span>Processing..</span>
                </LoadingButton>
                <LoadingButton
                  color="secondary"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className='d-md-none  w-100'
                  disabled
                >
                  <span>Processing..</span>
                </LoadingButton>
              </>
              :
              <>
                <Button
                  variant="contained"
                  onClick={bookRoom}
                  className='d-none d-md-flex'
                >Book  Facility
                </Button>
                <Button
                  variant="contained"
                  className=' d-md-none w-100'
                  style={{ backgroundColor: "rgb(128, 0, 0)" }}
                  onClick={bookRoom}
                >Book  Facility
                </Button>
              </>

          }

        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default BookConference

//58K i7
// 46K