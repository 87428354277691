import { Button, TextField } from '@mui/material';
import React from 'react'
import Modal from 'react-bootstrap/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import axios from 'axios';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Divider from '@mui/material/Divider';
import { Carousel, Col, Row, Table } from 'react-bootstrap';
import Swal from 'sweetalert2'
import { format, parse } from 'date-fns';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import VIP from '../../assets/images/rooms/VIP Room.jpeg'
import Executive from '../../assets/images/rooms/executive.jpeg'
import Superior from '../../assets/images/rooms/VIP.jpeg'
import moment from 'moment/moment';

const BookDetails = (props) => {
  const [fullName, setFullName] = React.useState('');
  const [roomType, setRoomType] = React.useState({
    type: 'Executive Room (Bed and breakfast)',
    price: 'KES 5,500',
    images: [Executive]
  },);
  const [roomNumber, setRoomNumber] = React.useState('');
  const [adults, setAdults] = React.useState('');
  const [children, setChildren] = React.useState('');
  const [checkInDate, setCheckInDate] = React.useState('');
  const [checkoutDate, setCheckOutDate] = React.useState('');
  const [showSummary, setShowSummary] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [paymentMode, setPaymentMode] = React.useState('Cash');
  const [email, setEmail] = React.useState('');
  const [nationality, setNationality] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const [isFetching, setIsfetching] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [paymentShow, setPaymentShow] = React.useState(false);
  const [processpayment, setProcessPayment] = React.useState(false);
  const [selectedRoom, setSelectedRoom] = React.useState();
  const [successMsg, setSuccessMsg] = React.useState();

  const success = () => {
    Swal.fire(
      'Success!',
      { successMsg },
      'success'
    )
  }


  const GetRoom = (id) => {
    setIsfetching(true)

    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/Booking/GetBookingById?id=${id}`)
      .then((response) => {
        setIsfetching(false)
        setSelectedRoom(response.data.$values)
        console.log(response.data.$values);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const CancelBooking = (id) => {
    setLoading(true)


    axios
      .put(`https://api-dhejomel.azgard.co.ke/api/Booking/CancelBooking?id=${id}`)
      .then((response) => {
        setLoading(false)
        setSuccessMsg("Reservation Cancelled Successfully!")
        success()
        props.onHide()
      })
      .catch((e) => {
        console.log(e);
      });
  };



  React.useEffect(() => {
    setLoading(false)
    GetRoom(props.rowId)
  }, [props.show])



  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
            Booking Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            isFetching ? <>
              <div className="d-flex w-100">
                <p>loading..</p>
              </div>
            </> :
              <Table striped bordered hover variant="light">
                <tbody>
                  <tr >
                    <td><strong>Full Name</strong></td>
                    <td colSpan={3}>{selectedRoom?.fullName}</td>
                  </tr><tr >
                    <td><strong>Phone Number</strong></td>
                    <td colSpan={3}>{selectedRoom?.phoneNumber}</td>
                  </tr><tr >
                    <td><strong>Email</strong></td>
                    <td colSpan={3}>{selectedRoom?.emailAddress}</td>
                  </tr><tr >
                    <td><strong>No. of Children</strong></td>
                    <td colSpan={3}>{selectedRoom?.numberOfChildren}</td>
                  </tr>
                  <tr >
                    <td><strong>Number of Adults</strong></td>
                    <td colSpan={3}>{selectedRoom?.numberOfAdults
                    }</td>
                  </tr>
                  <tr >
                    <td><strong>Nationality</strong></td>
                    <td colSpan={3}>{selectedRoom?.nationality
                    }</td>
                  </tr>
                  <tr >
                    <td><strong>Room Type</strong></td>
                    <td colSpan={3}>{selectedRoom?.roomType
                    }</td>
                  </tr>
                  <tr >
                    <td><strong>Check In Date</strong></td>
                    <td colSpan={3}>{moment(selectedRoom?.checkInDate).fromNow()}</td>
                  </tr>
                  <tr >
                    <td><strong>Checkout Date</strong></td>
                    <td colSpan={3}>{moment(selectedRoom?.checkOutDate).fromNow()}</td>
                  </tr>
                  <tr >
                    <td><strong>Payment Option</strong></td>
                    <td colSpan={3}>{selectedRoom?.paymentOption}</td>
                  </tr>
                  <tr >
                    <td><strong>Payment Status</strong></td>
                    <td colSpan={3}>{selectedRoom?.isPaid ? "Paid" : "Not Paid"}</td>
                  </tr>
                </tbody>
              </Table>
          }

        </Modal.Body>
        <Modal.Footer className='w-100 '>
          <>
            {
              isLoading ?
                <>
                  <LoadingButton
                    color="secondary"
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled
                  >
                    <span>Processing..</span>
                  </LoadingButton>
                </>
                :
                <div className="w-100">

                  <div className={`${selectedRoom?.isCancelled ? "d-none" : "d-flex w-100 justify-content-between"}`}>
                    <Button
                      variant="contained"
                      className=' py-2 bg-danger'
                      onClick={() => props.cancel(props?.rowId)}
                      disabled={selectedRoom?.isCancelled}
                    >Cancel Booking
                    </Button>
                    {
                      selectedRoom?.isPaid ?
                        <Button
                          variant="contained"
                          className='py-2  '
                          disabled={selectedRoom?.isPaid}

                        >Service Paid
                        </Button>
                        :
                        <Button
                          variant="contained"
                          className='py-2  bg-success'
                          disabled={selectedRoom?.isPaid}
                          onClick={() => {
                            props.confirmPayment()
                            setLoading(true)
                          }}
                        >Confirm Payment
                        </Button>
                    }
                  </div>
                </div>
            }
          </>

        </Modal.Footer>

      </Modal>
    </div>
  )
}

export default BookDetails

//1 VIP
//10 Executive
//2 Superior

//No. of people expected
//special