import React from 'react'
import ExpenseData from './componentss/expenseData'
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import AddCardIcon from '@mui/icons-material/AddCard';
import AddExpense from './addExpense';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import axios from 'axios';

function ExpenseTransactions(props) {
  const [showAddExpense, setShowAddExpense] = React.useState(false)
  const [expenseData, setExpenseData] = React.useState([])

  const fetchExpense = (props) => {

    axios
      .get("https://api-dhejomel.azgard.co.ke/api/Expenses/GetAllExpenses")
      .then((response) => {
        console.log(response.data);
        setExpenseData(response.data.$values)
      }).catch((e) => {
        console.log(e.response.message)
      });

  }


  React.useEffect(() => {
    fetchExpense()
  }, [])


  return (
    <div className='mt-4'>
      <div className="d-flex w-100 justify-content-between align-items-center">
        <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" onClick={()=> props.hideExpense()}>
            Expense Summary
          </Link>
          {/* <Link
            underline="hover"
            color="inherit"
            href="/material-ui/getting-started/installation/"
          >
            Core
          </Link> */}
          <Typography color="text.primary">{showAddExpense ? "All Expense" : "Add Expense"}</Typography>
        </Breadcrumbs>

        <Button variant="outlined" startIcon={<AddCardIcon />} className='mb-3' onClick={() => setShowAddExpense(true)}>
          Add Expense
        </Button>
      </div>
      {
        showAddExpense ?
          <AddExpense
            showExpense={setShowAddExpense}
            fetchExpense={() => fetchExpense()}
          /> :
          <ExpenseData data={expenseData} />
      }
    </div>
  )
}

export default ExpenseTransactions