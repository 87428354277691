import React from 'react'
import BackEndheader from '../components/partials/backHeader'
import { Container, Divider, Paper, Typography } from '@mui/material'
import FinanceStatistics from '../components/Finance/financeStatictics'
import LatestStatistics from '../components/Finance/latestStatistics'
import StreamsStats from '../components/Finance/streamsStats'
import DailyStats from '../components/Finance/dailyStats'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from "react-router-dom";

const Finance = () => {

  return (
    <div style={{ backgroundColor: "whitesmoke", minHeight: "100vh" }}>
      <BackEndheader />
      <Container maxWidth="xl">
        <div className="d-flex justify-content-between align-items-center">
          <Typography variant='h4' gutterBottom className='mt-4'>
            Revenue Analytics
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            {/* <Link underline="hover" color="inherit" href="/">
          Home
        </Link> */}
            <Link
              underline="hover"
              color="grey"
              to="/expense"
            >
              <Typography color="text.primary">Expense Analytics</Typography>
            </Link>
            <Typography color="text.primary">Revenue</Typography>
          </Breadcrumbs>
        </div>
        <Divider gutterBottom />
        <div className="d-flex justify-content-between mt-3">
          <Paper elevation={0}
            style={{
              minHeight: "150px",
              width: "100%",
              backgroundColor: "#B7D1DA"
            }} >
            <div className="px-4 pt-4">
              <Typography variant='h6' gutterBottom className=''>
                Daily Revenue
              </Typography>
              <Divider />
              <Typography variant='h4' gutterBottom className=''>
                KES 40,000
              </Typography>
            </div>
            <div className="d-flex">
              <div className="check bg-white me-2" style={{ width: "18px", height: "20px" }} />
              {/* <Typography variant='subtitle2'>Today</Typography> */}
            </div>
          </Paper>
          <Paper elevation={0} style={{
            height: "150px",
            width: "100%",
            backgroundColor: "#D2D5DD"
          }} className='mx-3 '>
            <div className="px-4 pt-4">
              <Typography variant='h6' gutterBottom className=''>
                Weekly Revenue
              </Typography>
              <Divider />
              <Typography variant='h4' gutterBottom className=''>
                KES 400,000
              </Typography>
            </div>
            <div className="d-flex">
              <div className="check bg-white me-2" style={{ width: "18px", height: "20px" }} />
              {/* <Typography variant='subtitle2'>Today</Typography> */}
            </div>

          </Paper>
          <Paper elevation={0} style={{
            height: "150px",
            width: "100%",
            backgroundColor: "#9DD9D2"
          }} >
            <div className="px-4 pt-4">
              <Typography variant='h6' gutterBottom className=''>
                Monthly Revenue
              </Typography>
              <Divider />
              <Typography variant='h4' gutterBottom className=''>
                KES 4,000,000
              </Typography>
            </div>
            <div className="d-flex">
              <div className="check bg-white me-2" style={{ width: "18px", height: "20px" }} />
              {/* <Typography variant='subtitle2'>Today</Typography> */}
            </div>
          </Paper>
          <Paper elevation={0} style={{
            height: "150px",
            width: "100%",
            backgroundColor: "#C4D7F2"
          }} className='ms-3  '>
            <div className="px-4 pt-4">
              <Typography variant='h6' gutterBottom className=''>
                Annual Revenue
              </Typography>
              <Divider />
              <Typography variant='h4' gutterBottom className=''>
                KES 40,000,000
              </Typography>
            </div>
            <div className="d-flex">
              <div className="check bg-white me-2" style={{ width: "18px", height: "20px" }} />
              {/* <Typography variant='subtitle2'>Today</Typography> */}
            </div>
          </Paper>

        </div>

        <div className="d-flex mt-5">
          <Paper elevation={0} style={{ height: "450px", width: "67%" }} className=' bg-white p-4'>
            <DailyStats />
          </Paper>
          <Paper elevation={0} style={{ height: "450px", width: "33%" }} className='ms-3 bg-white p-4'>
            {/* <Typography>Latest Transactions</Typography> */}
            <StreamsStats />
          </Paper>

        </div>

        <div className="d-flex mt-5 pb-5">
          <Paper elevation={0} style={{ height: "450px", width: "67%" }} className=' bg-white p-4'>
            <FinanceStatistics />
          </Paper>
          <Paper elevation={0} style={{ height: "450px", width: "33%" }} className='ms-3 bg-white p-4'>
            {/* <Typography>Latest Transactions</Typography> */}
            <LatestStatistics />
          </Paper>

        </div>

      </Container>
    </div>
  )
}

export default Finance