import React from "react";
import { Chart } from "react-google-charts";
import axios from 'axios';

const ExpensePieChart = () => {
  const [expenseData, setExpenseData] = React.useState([])
  const [salesData, setSalesData] = React.useState([])
  const [isFetching, setIsFetching] = React.useState(false)

  const fetchExpense = async () => {
    console.log("Initiating fetch");

    setIsFetching(true);

    try {
      const response = await axios.get("https://api-dhejomel.azgard.co.ke/api/Expenses/GetAllExpenses");

      // Access the data directly, as Axios automatically parses JSON
      const data = response.data;

      // Calculate total amount
      const totalAmount = data.$values.reduce((acc, item) => acc + item.amount, 0);

      // Update the state with the total amount
      setExpenseData(totalAmount);

      console.log("Total Amount:", totalAmount);
      console.log("Fetch successful");
    } catch (error) {
      // Handle the error, and log the error message
      console.error("Error fetching expenses:", error.message);
    } finally {
      setIsFetching(false);
    }
  };


  const fetchSales = async () => {
    console.log("Initiating fetch");

    setIsFetching(true);

    try {
      const response = await axios.get("https://api-dhejomel.azgard.co.ke/api/Sales/GetAllSales");

      // Access the data directly, as Axios automatically parses JSON
      const data = response.data;

      // Calculate total amount
      const totalAmount = data.$values.reduce((acc, item) => acc + item.amount, 0);

      // Update the state with the total amount
      setSalesData(totalAmount);

      console.log("Total Sales Amount:", totalAmount);
      console.log("Sales data fetch successful");
    } catch (error) {
      // Handle the error, and log the error message
      console.error("Error fetching sales data:", error.message);
    }
    finally {
      setIsFetching(false);
    }
  };

  React.useEffect(() => {
    fetchExpense()
    fetchSales()
  }, [])

  const options = {
    title: "Sales By Expenses",
    sliceVisibilityThreshold: 0.2, // 20%
    is3D: true,
    enableInteractivity: true,
    legend: {
      position: 'bottom',
      alignment: 'center',

    }
  };

  const totalData = [
    ["Expenses", "Sales Vs Expenses"],
    ["Sales", salesData],
    ["Expenses", expenseData],
  ];

  return (
    <>
      {
        isFetching ? <div > Loading ....</div> :
          <Chart
            chartType="PieChart"
            data={totalData}
            options={options}
            width="100%"
            height="100%"
          />
      }
    </>
  )
}

export default ExpensePieChart