import { Button, Divider } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { Table } from 'react-bootstrap'
import BookRoom from '../components/bookRoom'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import DataSetUp from '../components/Datalist/dataSetUp'
import BookConference from '../components/confModal'
import ConferenceDataSetup from '../components/Datalist/conferenceDataSetup'
import CommentSetup from '../components/Datalist/comentSetups'
import Dashboard from '../components/Dashboard/dashboard'
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import GroupsIcon from '@mui/icons-material/Groups';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import EventIcon from '@mui/icons-material/Event';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineSharpIcon from '@mui/icons-material/PersonOutlineSharp';
import { InfinitySpin } from 'react-loader-spinner'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Rooms from '../components/Dashboard/room'
import KingBedIcon from '@mui/icons-material/KingBed';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import Transactions from '../components/Datalist/transactions'
import BackEndheader from '../components/partials/backHeader'


const Home = () => {
  const [bookings, setBookings] = React.useState([])
  const [bookingShow, setBookingShow] = React.useState(false);
  const [conferenceBookingShow, setConferenceShow] = React.useState(false);
  const [isShowCancelled, setShowCancelled] = React.useState(false);
  const pages = ['Bookings', 'Finance', 'Customers', 'Staff', 'Assets'];
  const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState('bookings');
  const [selectedSection, setSelectedSection] = React.useState('booking');
  const [isFetching, setIsFetching] = React.useState(false);
  const [loggedInUser, setLoggedInUser] = React.useState(localStorage.getItem("DhejomelUser"));
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("DhejomelToken")
    localStorage.removeItem("DhejomelUser")
    window.location = '/'
  }

  const listStyle = {
    //backgroundColor: "rgb(255, 255, 255,0.9)",
    color: "white"
  }

  const activeTabStyle = {
    backgroundColor: '#FFD700', // Change this to the desired color
    color: 'white', // Change this to the desired color
  };

  const fetchBookings = () => {
    setActiveTab('bookings');
    setIsFetching(true)
    axios
      .get("https://api-dhejomel.azgard.co.ke/api/Booking/GetBookings")
      .then((response) => {
        setBookings(response.data.$values)
        setShowCancelled(false)
        setTimeout(() => {
          setIsFetching(false)// Set loading state to false after 3 seconds
        }, 1000);


      }).catch((e) => {
        console.log(e)
      });

  }

  //https://api-dhejomel.azgard.co.ke/api/Transactions
  const fetchTransactions = () => {
    setActiveTab('transactions');
    setIsFetching(true)
    axios
      .get("https://api-dhejomel.azgard.co.ke/api/Transactions")
      .then((response) => {
        setBookings(response.data.$values)
        // setShowCancelled(false)
        setTimeout(() => {
          setIsFetching(false)// Set loading state to false after 3 seconds
        }, 1000);


      }).catch((e) => {
        console.log(e)
      });

  }

  const fetchReservation = () => {
    setIsFetching(true)
    setActiveTab('reservations');

    axios
      .get("https://api-dhejomel.azgard.co.ke/api/Booking/GetReserved")
      .then((response) => {

        setBookings(response.data.$values)
        setActiveTab('reservations');
        setTimeout(() => {
          setIsFetching(false)// Set loading state to false after 3 seconds
        }, 1000);


      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchComments = () => {
    setIsFetching(true)
    setActiveTab('comments');

    axios
      .get("https://api-dhejomel.azgard.co.ke/api/Comments/GetComments")
      .then((response) => {
        setBookings(response.data.$values)
        setTimeout(() => {
          setIsFetching(false)// Set loading state to false after 3 seconds
        }, 1000);


      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchConference = () => {
    setIsFetching(true)
    setActiveTab('conference');

    axios
      .get("https://api-dhejomel.azgard.co.ke/api/Conference/ConferenceBookings")
      .then((response) => {

        setBookings(response.data.$values)
        setTimeout(() => {
          setIsFetching(false)// Set loading state to false after 3 seconds
        }, 1000);


      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchCancelled = () => {
    setIsFetching(true)
    setActiveTab('cancelled');

    axios
      .get("https://api-dhejomel.azgard.co.ke/api/Booking/GetCancelled")
      .then((response) => {

        setBookings(response.data.$values)
        setTimeout(() => {
          setIsFetching(false)// Set loading state to false after 3 seconds
        }, 1000);


      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSelection = (selection) => {
    switch (selection) {
      case 'comments':
        return isFetching ? fetchingData() : <CommentSetup data={bookings} fetchBookings={() => fetchBookings()} />

        break;
      case 'dashboard':
        return <Dashboard loggedInUser={loggedInUser} />

        break;
    
      case 'rooms':
        return <Rooms />

        break;
      case 'conference':
        return isFetching ? fetchingData() : <ConferenceDataSetup data={bookings} fetchBookings={() => fetchConference()} />

        break;
      case 'booking':
        return isFetching ? fetchingData() : <DataSetUp data={bookings} fetchBookings={() => fetchBookings()} />
        break;

      case 'transactions':
        return isFetching ? fetchingData() : <Transactions data={bookings} fetchBookings={() => fetchTransactions()} />
        break;

      default:
        return isFetching ? fetchingData() : <DataSetUp data={bookings} fetchBookings={() => fetchBookings()} />

        break;
    }
  }

  const toggleColorOne = { color: activeTab === 'dashboard' ? "black" : "#F5F5F5" }
  const toggleColorTwo = { color: activeTab === 'bookings' ? "black" : "#F5F5F5" }
  const toggleColorThree = { color: activeTab === 'reservations' ? "black" : "#F5F5F5" }
  const toggleColorFour = { color: activeTab === 'cancelled' ? "black" : "#F5F5F5" }
  const toggleColorFive = { color: activeTab === 'conference' ? "black" : "#F5F5F5" }
  const toggleColorSix = { color: activeTab === 'comments' ? "black" : "#F5F5F5" }
  const toggleColorSeven = { color: activeTab === 'rooms' ? "black" : "#F5F5F5" }
  const toggleColorEight = { color: activeTab === 'transactions' ? "black" : "#F5F5F5" }

  const fetchingData = () => {
    return (
      <div>
        <div
          className="container d-flex bg-white w-100  justify-content-center align-items-center"
          style={{ height: "60vh" }}
        >

          <InfinitySpin
            width='200'
            color="rgba(128, 0, 0, 0.9)"
          />
        </div>
      </div>
    )
  }


  React.useEffect(() => {
    var user = JSON.parse(localStorage.getItem("DhejomelUser"))

    setLoggedInUser(user)


  }, [])


  return (
    <div style={{ backgroundColor: "whitesmoke", maxHeight: "100vh", margin: 0, padding: 0, overflowY: "scroll" }}>

      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>


      <BackEndheader />
      <div className="d-flex" style={{ minHeight: "94vh" }}>


        <div
          className=" d-flex flex-column justify-content-between  tabs"
          style={{
            paddingTop: "60px",
            backgroundColor: "rgb(128, 0, 0,0.9)"
          }}>
          <ul style={{ listStyle: "none", }} className=' m-0 p-0 px-2 '>
            <li
              className={`p-2  ${activeTab === 'dashboard' ? 'active' : ''}`}
              style={{
                backgroundColor: activeTab === 'dashboard' ? activeTabStyle.backgroundColor : listStyle.backgroundColor, borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSelectedSection('dashboard');
                setActiveTab('dashboard');

              }}
            >
              <div className="d-flex align-items-center">
                <DashboardIcon className='me-1' style={toggleColorOne} />
                <Typography variant="h6" className=" p-0 m-0 text-center" style={toggleColorOne}>Dashboard</Typography>
              </div>
            </li>

            <li
              className={`p-2  ${activeTab === 'rooms' ? 'active' : ''}`}
              style={{
                backgroundColor: activeTab === 'rooms' ? activeTabStyle.backgroundColor : listStyle.backgroundColor, borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSelectedSection('rooms');
                setActiveTab('rooms');

              }}
            >
              <div className="d-flex align-items-center">
                <KingBedIcon className='me-1' style={toggleColorSeven} />
                <Typography variant="h6" className=" p-0 m-0 text-center" style={toggleColorSeven}>Rooms</Typography>
              </div>
            </li>

            <li
              className={`p-2  ${activeTab === 'bookings' ? 'active' : ''}`}

              style={{
                backgroundColor: activeTab === 'bookings' ? activeTabStyle.backgroundColor : listStyle.backgroundColor, borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSelectedSection('booking');
                fetchBookings();
              }}
            >
              <div className="d-flex align-items-center">
                <EventAvailableIcon className='me-1' style={toggleColorTwo} />
                <Typography variant="h6" className="p-0 m-0 text-center" style={toggleColorTwo}>Bookings</Typography>
              </div>
            </li>

            <li
              className={`p-2  ${activeTab === 'reservations' ? activeTabStyle : ''}`}
              style={{ backgroundColor: activeTab === 'reservations' ? activeTabStyle.backgroundColor : listStyle.backgroundColor, borderRadius: '5px', cursor: 'pointer', }}
              onClick={() => {
                setSelectedSection('booking');
                fetchReservation();
              }}
            >
              <div className="d-flex align-items-center">
                <EventIcon className='me-1' style={toggleColorThree} />
                <Typography variant="h6" className="p-0 m-0" style={toggleColorThree} >Reservations</Typography>
              </div>

            </li>

            <li
              className={`p-2 ${activeTab === 'cancelled' ? activeTabStyle : ''}`}
              style={{ backgroundColor: activeTab === 'cancelled' ? activeTabStyle.backgroundColor : listStyle.backgroundColor, borderRadius: '5px', cursor: 'pointer', }}
              onClick={() => {
                setSelectedSection('booking');
                fetchCancelled();
              }}
            >
              <div className="d-flex align-items-center">
                <EventBusyIcon className='me-1' style={toggleColorFour} />
                <Typography variant="h6" className="p-0 m-0" style={toggleColorFour}>Cancelled</Typography>
              </div>
            </li>

            <li className={`p-2   ${activeTab === 'conference' ? activeTabStyle : ''}`}
              style={{ backgroundColor: activeTab === 'conference' ? activeTabStyle.backgroundColor : listStyle.backgroundColor, borderRadius: '5px', cursor: 'pointer', }}
              onClick={() => {
                setSelectedSection('conference');
                fetchConference()
              }}
            > <div className="d-flex align-items-center">
                <GroupsIcon className='me-1' style={toggleColorFive} />
                <Typography variant="h6" className="p-0 m-0" style={toggleColorFive}>Conference</Typography>
              </div>

            </li>

            <li className={`p-2   ${activeTab === 'transactions' ? activeTabStyle : ''}`}
              style={{ backgroundColor: activeTab === 'transactions' ? activeTabStyle.backgroundColor : listStyle.backgroundColor, borderRadius: '5px', cursor: 'pointer', }}
              onClick={() => {
                setSelectedSection('transactions');
                fetchTransactions()

              }}
            > <div className="d-flex align-items-center">
                <CreditScoreIcon className='me-1' style={toggleColorEight} />
                <Typography variant="h6" className="p-0 m-0" style={toggleColorEight}>Transactions</Typography>
              </div>

            </li>

            <li className={`p-2   ${activeTab === 'comments' ? activeTabStyle : ''}`}
              style={{ backgroundColor: activeTab === 'comments' ? activeTabStyle.backgroundColor : listStyle.backgroundColor, borderRadius: '5px', cursor: 'pointer', }}
              onClick={() => { setSelectedSection('comments'); fetchComments() }}
            >
              <div className="d-flex align-items-center" >
                <QuestionAnswerIcon className='me-1' style={toggleColorSix} />
                <Typography variant="h6" className="p-0 m-0" style={toggleColorSix}>Comments</Typography>
              </div>

            </li>



          </ul>
          <Button
            variant="outlined"
            className=' mx-2 '
            onClick={() => {
              handleLogout()
            }}
            style={{
              backgroundColor: "#FFD700",
              color: 'black',
              border: "0px",
              marginBottom: '80px'
            }}
          >
            <div className="d-flex align-items-center">
              <div className="me-2 py-1">Logout <LogoutIcon /></div>
            </div>
          </Button>
        </div>
        <div className="w-100  ">

          <div className="px-2 ">
            <div className="d-flex  justify-content-end">
              <Button
                variant="outlined"
                className='my-3 me-3'
                onClick={() => setConferenceShow(true)}
                style={{
                  borderColor: "rgb(128, 0, 0)",
                  color: "rgb(128, 0, 0)"
                }}
              >
                <div className="d-flex align-items-center">
                  <div className="me-2">Book Conference</div> <BorderColorIcon />
                </div>
              </Button>
              <Button
                variant="outlined"
                className='my-3'
                onClick={() => setBookingShow(true)}
                style={{
                  borderColor: "rgb(128, 0, 0,0.8)",
                  color: "rgb(128, 0, 0)"
                }}
              >
                <div className="d-flex align-items-center">
                  <div className="me-2">Book a Room</div> <BorderColorIcon />
                </div>
              </Button>
            </div>
            <Divider />


            {
              handleSelection(selectedSection)
            }
          </div>
        </div>
      </div>

      <BookRoom
        show={bookingShow}
        onHide={() => {
          setBookingShow(false)
          fetchReservation()
        }}
      />

      <BookConference
        show={conferenceBookingShow}
        fetchConference={() => fetchConference()}
        onHide={() => {
          setConferenceShow(false)
        }}
      />


    </div>
  )
}

export default Home