import React from "react";

const TableWithNamesAndRoles = () => {
  const data = [
    { id: 1, firstName: "John", lastName: "Doe", role: "Developer" },
    { id: 2, firstName: "Jane", lastName: "Smith", role: "Designer" },
    { id: 3, firstName: "Michael", lastName: "Johnson", role: "Manager" },
    { id: 4, firstName: "Emily", lastName: "Brown", role: "Tester" },
    { id: 5, firstName: "Chris", lastName: "Davis", role: "Analyst" },
  ];

  return (
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">First Name</th>
          <th scope="col">Last Name</th>
          <th scope="col">Role</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            <th scope="row">{item.id}</th>
            <td>{item.firstName}</td>
            <td>{item.lastName}</td>
            <td>{item.role}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableWithNamesAndRoles;
