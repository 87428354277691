import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from '../pages/login';
import Home from '../pages/home';
import Index from '../pages/frontend';
import Contact from '../pages/frontend/contact';
import Services from '../pages/frontend/services';
import Rooms from '../pages/frontend/rooms';
import Finance from '../pages/finance';
import Assets from '../pages/Assets/dashboard';
import Expense from '../pages/Expense/expense';
import StaffDashboard from '../pages/staff/dashboard';
import AllAssets from '../pages/Assets/assets';
import FinanceRoutes from './financeRoutes';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      {/* <Route exact key="homepage" path="/" component={Table} />, */}
      <Route exact key="login" path="/admin" component={Login} />,
      <Route exact key="staff" path="/staff" component={Home} />,
      <Route exact key="front" path="/" component={Index} />,
      <Route exact key="contact" path="/contact" component={Contact} />,
      <Route exact key="contact" path="/services" component={Services} />,
      <Route exact key="rooms" path="/rooms" component={Rooms} />,
      <Route exact key="finance" path="/finance-summary" component={Finance} />,
      <Route exact key="assets" path="/assets" component={Assets} />,
      <Route exact key="assets" path="/all-assets" component={AllAssets} />,
      <Route exact key="expense" path="/expense" component={Expense} />,
      <Route exact key="staffDashboard" path="/shift" component={StaffDashboard} />,
      <Route exact key="FinanceRoutes" path="/finance" component={FinanceRoutes} />,

    </Switch>
  </BrowserRouter >
);

export default Routes;
