import { Alert, Button, TextField, Typography } from '@mui/material';
import React from 'react'
import Modal from 'react-bootstrap/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import axios from 'axios';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Divider from '@mui/material/Divider';
import { Carousel, Col, Row } from 'react-bootstrap';
import Swal from 'sweetalert2'
import { format, parse } from 'date-fns';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Payment from './payment';
import VIP from '../assets/images/rooms/VIP Room.jpeg'
import Executive from '../assets/images/rooms/executive.jpeg'
import Superior from '../assets/images/rooms/VIP.jpeg'
import { LineWave, ThreeDots } from 'react-loader-spinner';

const BookingModal = (props) => {
  const [fullName, setFullName] = React.useState('');
  const [roomType, setRoomType] = React.useState({
    type: 'VIP Room (Bed and Breakfast)',
    price: 'KES 8,500',
    category: '(Bed and Breakfast)',
    room: 'VIP',
    images: [VIP]
  });
  const [roomNumber, setRoomNumber] = React.useState(1);
  const [roomAvailability, setRoomAvailability] = React.useState('');
  const [adults, setAdults] = React.useState(1);
  const [children, setChildren] = React.useState(0);
  const [checkInDate, setCheckInDate] = React.useState('');
  const [checkoutDate, setCheckOutDate] = React.useState('');
  const [showSummary, setShowSummary] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [paymentOption, setPaymentOption] = React.useState('Reserve');
  const [paymentMode, setPaymentMode] = React.useState('Cash');
  const [email, setEmail] = React.useState('');
  const [nationality, setNationality] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [paymentShow, setPaymentShow] = React.useState(false);
  const [processpayment, setProcessPayment] = React.useState(false);
  const [theRooms, setTheRooms] = React.useState([]);

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const success = () => {
    Swal.fire(
      'Success!',
      'Room Booked Successfully!',
      'success'
    )
  }

  const paymentDetails = {
    fullName: fullName,
    phoneNumber: phoneNumber,
    amount: roomType.price
  };


  const bookRoom = () => {
    setLoading(true)
    const dateObjectOne = parse(checkInDate, "EEE, dd MMM yyyy HH:mm:ss 'GMT'", new Date());
    const formattedDateOne = format(dateObjectOne, "yyyy-MM-dd");

    const dateObjectTwo = parse(checkoutDate, "EEE, dd MMM yyyy HH:mm:ss 'GMT'", new Date());
    const formattedDateTwo = format(dateObjectTwo, "yyyy-MM-dd");

    const payload = {
      fullName: fullName,
      emailAddress: email,
      nationality: nationality,
      checkInDate: formattedDateOne,
      checkOutDate: formattedDateTwo,
      roomType: roomType.room,
      numberOfRooms: parseInt(roomNumber),
      numberOfAdults: parseInt(adults),
      numberOfChildren: parseInt(children),
      isReserved: true,
      isPaid: false,
      isCancelled: false,
      phoneNumber: phoneNumber,
      serviceCategory: roomType.category,
      price: parseInt(roomType.price),
      paymentOption: paymentOption
    };

    axios
      .post("https://api-dhejomel.azgard.co.ke/api/Booking/AddBooking", payload)
      .then((response) => {
        success()
        setLoading(false)
        setIsSubmitted(true)
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const checkAvailability = (checkInDate, roomType) => {
    setIsLoading(true)
    const dateObjectOne = parse(checkInDate, "EEE, dd MMM yyyy HH:mm:ss 'GMT'", new Date());
    const formattedDateOne = format(dateObjectOne, "yyyy-MM-dd");

    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/Booking/CheckRoomAvailability?date=${formattedDateOne}&roomType=${roomType}`)
      .then((response) => {
        // setRoomAvailability(response.data.$values)
        // setRoomAvailability(true)
        console.log(response.data);

        setTimeout(() => {
          setIsLoading(false)// Set loading state to false after 3 seconds
        }, 1000);

      }).catch((e) => {
        setTimeout(() => {
          setIsLoading(false)// Set loading state to false after 3 seconds
        }, 1000);
        setRoomAvailability(true)

        console.log(e.response)
      });
  }

  const GetRooms = () => {
    console.log('Getting rooms');
    setIsLoading(true)
    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/RoomService/GetCategories`)
      .then((response) => {
        setTheRooms(response.data.$values)
        console.log(response.data);
        console.log('success Getting rooms');

        setRoomType({
          type: response.data.$values[0].room.roomType + " Room (" + response.data[0].categoryName + ")",
          price: response.data.$values[0].categoryPrice,
          images: response.data.$values[0].room.images,
          category: response.data.$values[0].categoryName,
          room: response.data.$values[0].room.roomType
        })
        setIsLoading(false)

      }).catch((e) => {
        console.log(e.response?.message)
      });
  }

  const GetRoomsByNumberOfAdults = (adults) => {
    setTheRooms([])
    setIsLoading(true)
    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/RoomService/GetCategoriesByAdults?numberOfAdults=${adults}`)
      .then((response) => {
        setTheRooms(response.data.$values)
        console.log(response.data.$values);
        console.log('success Getting rooms');

        setRoomType({
          type: response.data.$values[0].room.roomType + " Room (" + response.data.$values[0].categoryName + ")",
          price: response.data.$values[0].categoryPrice,
          images: response.data.$values[0].room.images,
          category: response.data.$values[0].categoryName,
          room: response.data.$values[0].room.roomType
        })
        setIsLoading(false)

      }).catch((e) => {
        setIsLoading(false)

        console.log(e.response?.message)
      });
  }


  React.useEffect(() => {
    console.log(props.bookingDetails.checkinDate);
    setShowSummary(false)
    setAdults(props.bookingDetails.adults)
    setCheckInDate(props.bookingDetails.checkinDate)
    setCheckOutDate(props.bookingDetails.checkOutDate)
    setChildren(props.bookingDetails.children)
    setRoomNumber(props.bookingDetails.rooms)
    setIsSubmitted(false)
    setLoading(false)

    // GetRooms()
    GetRoomsByNumberOfAdults(props.bookingDetails.adults)
  }, [props.show])



  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
            Book A Room
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            showSummary ?
              <>
                <Row className="d-flex">
                  <Col lg={6} sm={12}>
                    <List
                      sx={{
                        width: '100%',
                        maxWidth: 360,
                        bgcolor: 'background.paper',
                      }}
                    >
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <ImageIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Full Name" secondary={fullName} />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <WorkIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Check In Date" secondary={checkInDate.slice(0, 16)} />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <BeachAccessIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="CheckOut Date" secondary={checkoutDate.slice(0, 16)} />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <BeachAccessIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Payment Mode" secondary={paymentMode} />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <BeachAccessIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Visitors" secondary={adults} />
                      </ListItem>
                    </List>
                  </Col>
                  <Col lg={6} sm={12}>

                    <div className="w-100 bg-info">
                      <Carousel>
                        {
                          roomType.images?.$values?.map((item, key) =>
                            <Carousel.Item key={key}>
                              <img
                                className="d-block  img-fluid"
                                src={item.imageUrl}
                                alt="First slide"
                              />
                            </Carousel.Item>
                          )
                        }

                      </Carousel>
                    </div>
                    <h5 className='py-3'><strong>Room Type: {roomNumber} {roomType.type + " - " + "KES " + roomType.price}</strong></h5>
                    {/* <p>Click submit to book the room</p> */}
                  </Col>
                </Row>
              </>
              :

              <Row className="row col-lg-12">
                <Col lg={6} sm={12}>


                  <TextField
                    id="outlined-basic"
                    label="Full Name"
                    variant="outlined"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    className='mb-3 w-100'
                  />

                  <TextField
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className='mb-3 w-100'
                  />

                  <TextField
                    id="outlined-basic"
                    label="Email Address"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className='mb-3 w-100'
                  />

                  <TextField
                    id="outlined-basic"
                    label="Nationality"
                    variant="outlined"
                    value={nationality}
                    onChange={(e) => setNationality(e.target.value)}
                    className='mb-3 w-100'
                  />

                  <FormControl sx={{ minWidth: 120 }}
                    className='mb-3 w-100'

                  >
                    <InputLabel
                      id="demo-simple-select-helper-label">{roomType.type}</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={roomType.type}
                      onChange={(e) => {
                        setRoomType(e.target.value)
                        checkAvailability(checkInDate, roomType.room)
                      }}
                      label={roomType.type}
                      className='mb-3 w-100'

                    >
                      <MenuItem value={roomType.type}>
                        {roomType.type + " - " + roomType.price}
                      </MenuItem>

                      {
                        theRooms?.map((item, key) =>
                          <MenuItem
                            value={{
                              type: item.room.roomType + " Room (" + item.categoryName + ")",
                              price: item.categoryPrice,
                              images: item.room.images,
                              category: item.categoryName,
                              room: item.room.roomType
                            }}
                            selected={roomType.type === item.room.roomType}>
                            {
                              item.room.roomType + " Room (" + item.categoryName + ")" + "-" + item.categoryPrice
                            }
                          </MenuItem>
                        )
                      }

                    </Select>
                  </FormControl>



                  <FormControl className=' w-100'
                  >
                    <FormLabel id="demo-radio-buttons-group-label">Payment Mode</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="Cash"
                      name="radio-buttons-group"
                      value={paymentMode}
                      onChange={(e) => setPaymentMode(e.target.value)}
                      className='d-flex'
                    >
                      {/* <FormControlLabel value="Mpesa" control={<Radio />} label="MPesa" onClick={() => setProcessPayment(true)} /> */}
                      <FormControlLabel
                        value="Cash"
                        control={<Radio />}
                        onClick={() => {
                          setProcessPayment(false)
                          setPaymentOption("Cash")
                        }}
                        label="Cash on Arrival"
                      />

                      <FormControlLabel
                        value="Reserve"
                        control={<Radio />}
                        onClick={() => {
                          setProcessPayment(false)
                          setPaymentOption("Reservation")
                        }}
                        label="Make Reservation"
                      />
                    </RadioGroup>
                  </FormControl>

                </Col>
                <Col lg={6} sm={12}>
                  <div className="room-details  w-100">

                    <div className="w-100 container">
                      <Carousel>
                        {
                          roomType.images?.$values?.map((item, key) =>
                            <Carousel.Item key={key}>
                              <img
                                className="d-block  img-fluid"
                                src={item.imageUrl}
                                alt="https://www.dhejomelshotel.co.ke/static/media/VIP%20Room.b1f45e96.jpeg"
                              />
                            </Carousel.Item>
                          )
                        }

                      </Carousel>
                    </div>
                    {
                      roomType === '' ?
                        <>
                        </>
                        :
                        <>
                          <Typography variant='subtitle2' className='p-2 px-3'>
                            <strong>Room Type: {roomNumber} {roomType.type + " - " + "KES " + roomType.price}</strong>
                          </Typography>
                          {
                            roomAvailability !== '' && <>
                              {
                                isLoading ?
                                  <>
                                    <div className="d-flex  justify-content-center">
                                      <ThreeDots
                                        height="50"
                                        width="80"
                                        radius="9"
                                        color="#4fa94d"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                      />

                                    </div>
                                  </> :
                                  <Alert severity={`${roomAvailability ? "success" : "error"}`}>
                                    {roomAvailability ? roomType.type + " room is available" : roomType.type + " are Full!"}
                                  </Alert>
                              }
                            </>
                          }
                        </>
                    }
                    {/* <p>Click submit to book the room</p> */}
                  </div>
                </Col>
              </Row >



          }

        </Modal.Body>
        {
          showSummary ?

            <>
              {
                isSubmitted ?
                  <>
                    <Modal.Footer>
                      <Button variant="contained" onClick={() => props.onHide()}>Exit</Button>
                    </Modal.Footer>
                  </>
                  :
                  <>
                    {
                      loading ?
                        <Modal.Footer >
                          <LoadingButton
                            color="secondary"
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            disabled
                          >
                            <span>Processing..</span>
                          </LoadingButton>
                        </Modal.Footer>
                        :
                        <Modal.Footer >
                          <Button
                            variant="contained"
                            className='d-none d-md-flex'
                            onClick={() => bookRoom()}
                          >
                            Submit
                          </Button>
                          <Button
                            variant="contained"
                            className='d-md-none w-100'
                            style={{ backgroundColor: "rgb(128, 0, 0)" }}
                            onClick={() => bookRoom()}
                          >Submit
                          </Button>
                        </Modal.Footer>
                    }
                  </>
              }
            </>

            :
            <>
              {
                processpayment ?
                  <Modal.Footer>
                    <Button variant="contained" onClick={() => setPaymentShow(true)} className='bg-success'>Process Payment</Button>
                  </Modal.Footer>
                  :
                  <Modal.Footer>
                    <Button
                      variant="contained"
                      className='d-none d-md-flex '
                      onClick={() => setShowSummary(true)}
                      disabled={!roomAvailability}
                    >Confirm
                    </Button>
                    <Button
                      variant="contained"
                      className='d-md-none w-100 '
                      style={{ backgroundColor: "rgb(128, 0, 0)" }}
                      onClick={() => setShowSummary(true)}
                      disabled={!roomAvailability}
                    >Confirm
                    </Button>
                  </Modal.Footer>
              }
            </>

        }

      </Modal>

      <Payment
        show={paymentShow}
        onHide={() => {
          setPaymentShow(false)
        }}
        paymentDetails={paymentDetails}
      />
    </div>
  )
}

export default BookingModal

//1 VIP
//10 Executive
//2 Superior

//No. of people expected
//special