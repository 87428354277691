import { LoadingButton } from '@mui/lab';
import { Alert, Button, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import SaveIcon from '@mui/icons-material/Save';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
import CancelIcon from '@mui/icons-material/Cancel';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import sad from "../../assets/images/not-available.jpg"

const AssignRoom = (props) => {
  const [selectedGuest, setSelectedGuest] = React.useState('');
  const [guestNames, setGuestNames] = React.useState('');
  const [noOfGuests, setNoOfGuests] = React.useState(1);
  const [guests, setGuests] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);


  const handleChange = (event) => {
    setSelectedGuest(event.target.value);
  };

  const fetchGuests = () => {
    setIsLoading(true)
    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/Booking/GetGuestWithNoRoomsByDate?date=2023-08-07&roomType=${props.selectedRoomType}`)
      .then((response) => {
        setIsLoading(false)

        setGuests(response.data.$values)
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const assignRoom = () => {
    const payload = {
      bookedRoomNumber: props.selectedRoom.bookedRoomNumber,
      guestNames: guestNames,
      numberOfGuests: 2,
      bookingId: selectedGuest.id
    }
    axios
      .post("https://api-dhejomel.azgard.co.ke/api/Booking/AssignRoom", payload)
      .then((response) => {

        setGuests([])
        setSelectedGuest('')
        setNoOfGuests(1)
        setGuestNames('')
        props.fetchBookedRooms()
        props.onHide()
      })
      .catch((e) => {
        console.log(e);
      });
  };



  React.useEffect(() => {
    fetchGuests()
    setGuests([])
    setSelectedGuest('')
    setNoOfGuests(1)
    setGuestNames('')
  }, [props.show])

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
            Assign Room to Guest
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "430px" }}>
          <>
            {
              isLoading ?

                <div
                  className="d-flex justify-content-center align-items-center "
                  style={{ height: "400px" }}
                >
                  <ThreeDots
                    height="50"
                    width="80"
                    radius="9"
                    color="rgba(128, 0, 0, 0.9)"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />

                </div>
                :
                <>
                  {
                    guests.length !== 0 ?
                      <>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Booked By</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedGuest}
                            label="Booked By"
                            onChange={handleChange}
                          >
                            {
                              guests.map((item) =>
                                <MenuItem
                                  value={item}
                                >{item.fullName}</MenuItem>
                              )
                            }

                          </Select>
                        </FormControl>

                        <TextField
                          id="outlined-number"
                          label="Number of Guests"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => setNoOfGuests(e.target.value)}
                          value={noOfGuests}
                          fullWidth
                          className='my-3'
                        />

                        <TextField
                          id="outlined-basic"
                          label="Guests Names"
                          variant="outlined"
                          fullWidth
                          className=''
                          value={guestNames}
                          onChange={(e) => setGuestNames(e.target.value)}
                        />

                        <TextField
                          fullWidth
                          className='mt-3'
                          id="outlined-read-only-input"
                          label="VIP"
                          defaultValue={props.selectedRoomType}
                          InputProps={{
                            readOnly: true,
                          }}
                        />

                        <TextField
                          fullWidth
                          className='mt-3'
                          id="outlined-read-only-input"
                          label="Assign Room Number"
                          defaultValue={props.selectedRoom.bookedRoomNumber}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </>
                      :
                      <>
                        <div
                          className="d-flex justify-content-center flex-column align-items-center "
                          style={{ height: "400px" }}
                        >
                          <div
                            className="sad mb-3"
                            style={{
                              width: "250px"
                            }}
                          >
                            <img
                              src={sad} // Replace 'sad' with the correct image path
                              alt="Sad Emoji" // Provide a meaningful alt description
                              className="img-fluid" // Use the 'img-fluid' class for responsive images
                            />
                          </div>

                          <h4>Oops! Nobody Booked {props.selectedRoomType}  Oooh!</h4>

                        </div>
                      </>
                  }
                </>
            }
          </>



        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex w-100 justify-content-between">
            <Button
              variant="contained"
              className='w-25 bg-danger py-2'
              onClick={() => props.onHide()}
            >
              Cancel
            </Button>
            <>
              {
                guests.length !== 0 && <Button
                  variant="contained"
                  className='w-25 bg-success py-2'
                  onClick={() => assignRoom()}
                >
                  Confirm
                </Button>

              }
            </>


          </div>
        </Modal.Footer>
      </Modal>


    </div>
  )
}

export default AssignRoom

