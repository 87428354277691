import React from "react";
import { Chart } from "react-google-charts";
import axios from 'axios';

const ExpenseSummary = () => {
  const [salariesData, setSalariesData] = React.useState([])
  const [furnitureData, setFurnitureData] = React.useState([])
  const [electronicsData, setElectronicsData] = React.useState([])
  const [cutleryData, setCutleryData] = React.useState([])
  const [consumablesData, setConsumablesData] = React.useState([])
  const [isFetching, setIsFetching] = React.useState(false)

  const fetchData = async (Item) => {
    console.log("Initiating Categories fetch");

    setIsFetching(true);

    try {
      const response = await axios.get(`https://api-dhejomel.azgard.co.ke/api/Expenses/GetAllExpenses?$select=Amount,Category&$filter=category eq '${Item}'`);

      // Access the data directly, as Axios automatically parses JSON
      const data = response.data;

      // Calculate total amount
      const totalAmount = data.$values.reduce((acc, item) => acc + item.Amount, 0);

      // Update the state with the total amount
      console.log(`Total ${Item} Amount:`, totalAmount);
      console.log("Fetch successful");
      return totalAmount;

    } catch (error) {
      // Handle the error, and log the error message
      console.error("Error fetching expenses:", error.message);
    } finally {
      setIsFetching(false);
    }
  };


  const fetchSalariesDataAsync = async () => {
    const result = await fetchData('Salaries');
    setSalariesData(result);
  };

  const fetchFurnitureDataAsync = async () => {
    const result = await fetchData('Furniture');
    setFurnitureData(result);
  };

  const fetchElectronicsDataAsync = async () => {
    const result = await fetchData('Electronics');
    setElectronicsData(result);
  };

  const fetchCutleryDataAsync = async () => {
    const result = await fetchData('Cutlery');
    setCutleryData(result);
  };

  const fetchConsumableDataAsync = async () => {
    const result = await fetchData('Consumable');
    setConsumablesData(result);
  };

  React.useEffect(() => {
    fetchSalariesDataAsync()
    fetchFurnitureDataAsync()
    fetchElectronicsDataAsync()
    fetchCutleryDataAsync()
    fetchConsumableDataAsync()
  }, [])
  const data = [
    ["Task", "Hours per Day"],
    ["Salaries", salariesData],
    ["Furniture", furnitureData],
    ["Consumables", consumablesData],
    ["Electronics", electronicsData],
    ["Cutlery", cutleryData],
  ];

  const options = {
    title: "Expense Summary ",
    pieHole: 0.4,
    is3D: false,
    enableInteractivity: true,
    legend: {
      position: 'bottom',
      alignment: 'center',

    }
  };

  return (
    <>
      {
        isFetching ?
          <div>Fetching....</div> :
          <Chart
            chartType="PieChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
          />
      }
    </>
  );
};

export default ExpenseSummary;
