import React from 'react'
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Swal from 'sweetalert2';
import axios from 'axios';
import BookDetails from '../Details/bookDetails';

function DataSetUp(props) {
  const [rowId, setRowId] = React.useState()
  const [showBookingDetails, setShowBookingDetails] = React.useState()
  const [isLoading, setLoading] = React.useState(false);

  const cancelReservation = (id) => {
    Swal.fire('Cancelling Reservation....', '', 'info')

    axios
      .put(`https://api-dhejomel.azgard.co.ke/api/Booking/CancelBooking?id=${id}`)
      .then((response) => {
        props.fetchBookings()
        Swal.fire('Reservation Cancelled!', '', 'success')
      })
      .catch((e) => {
        console.log(e);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: '<a href="">Check your Internet connection</a>'
        })
      });
  };

  const ConfirmPayment = (id) => {
    setLoading(true)
    axios
      .post(`https://api-dhejomel.azgard.co.ke/api/Booking/UpdatePayment?id=${id}`)
      .then((response) => {
        setLoading(false)
        props.fetchBookings()
        Swal.fire('Payment Confirmed!', '', 'success')
        setShowBookingDetails(false)
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleEdits = () => {
    Swal.fire({
      title: 'Are you Sure You want to cancel this Reservations?',
      showDenyButton: true,
      confirmButtonText: 'No',
      denyButtonText: `Yes Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Changes are not saved', '', 'info')

      } else if (result.isDenied) {
        cancelReservation(rowId)
      }
    })
  }


  const columns = [
    {
      name: 'FullName',
      selector: row => row.fullName,
      sortable: true,
    },
    {
      name: 'PhoneNo',
      selector: row => (row.phoneNumber === "") ? "-" : row.phoneNumber,
    },
    {
      name: 'RoomType',
      selector: row => row.roomType + " - " + row.serviceCategory,
    }, {
      name: 'No. of Rooms',
      selector: row => row.numberOfRooms,
      sortable: true,
    }, {
      name: 'Check In ',
      selector: row => moment(row.checkInDate).format('ll'),
      sortable: true,
    }, {
      name: 'Check Out ',
      selector: row => moment(row.checkOutDate).format('ll'),
      sortable: true,
    },

    {
      name: 'Status',
      selector: row => row.isPaid ? "Paid" : "Not Paid",
      sortable: true,
    },
    {
      name: 'Guests',
      selector: row => row.numberOfAdults + row.numberOfChildren,
      sortable: true,
    },
  ];



  return (
    <>
      <DataTable
        columns={columns}
        data={props.data}
        // selectableRows
        pagination
        highlightOnHover
        pointerOnHover
        onRowClicked={(row, event) => {
          setRowId(row.id)
          setShowBookingDetails(true)
        }}
        customStyles={{
          headRow: {
            style: {
              background: 'rgb(255, 215, 0,0.5)',
              color: "dark"
            },
          },
          pagination: {
            style: {
              background: 'rgb(255, 215, 0,0.3)',
              color: "dark"
            },
          },
        }}
      />
      <BookDetails
        show={showBookingDetails}
        onHide={() => setShowBookingDetails(false)}
        rowId={rowId}
        cancel={() => handleEdits()}
        confirmPayment={() => ConfirmPayment(rowId)}
      />
    </>
  )
}

export default DataSetUp