import React from 'react';
import { Button, Divider } from '@mui/material'
import { Container, Paper, Typography } from '@mui/material'
import PostAddIcon from '@mui/icons-material/PostAdd';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';

const weeklyData = [
  // Week 1
  {
    weekName: 'Week 1',
    Morning: {
      Monday: 'John Doe',
      Tuesday: 'Jane Smith',
      Wednesday: 'Bob Johnson',
      Thursday: 'Alice Williams',
      Friday: 'Chris Davis',
      Saturday: 'Emily Brown',
      Sunday: 'Michael Wilson',
    },
    Afternoon: {
      Monday: 'Alice Williams',
      Tuesday: 'Chris Davis',
      Wednesday: 'John Doe',
      Thursday: 'Jane Smith',
      Friday: 'Bob Johnson',
      Saturday: 'Emily Brown',
      Sunday: 'Michael Wilson',
    },
    Evening: {
      Monday: 'Bob Johnson',
      Tuesday: 'Jane Smith',
      Wednesday: 'Chris Davis',
      Thursday: 'Alice Williams',
      Friday: 'John Doe',
      Saturday: 'Michael Wilson',
      Sunday: 'Emily Brown',
    },
  },
  // Week 2 (similar structure with different names)
  // {
  //   weekName: 'Week 2',
  //   Morning: {
  //     Monday: 'Michael Wilson',
  //     Tuesday: 'Emily Brown',
  //     Wednesday: 'Alice Williams',
  //     Thursday: 'Chris Davis',
  //     Friday: 'Bob Johnson',
  //     Saturday: 'John Doe',
  //     Sunday: 'Jane Smith',
  //   },
  //   Afternoon: {
  //     Monday: 'Chris Davis',
  //     Tuesday: 'Bob Johnson',
  //     Wednesday: 'Michael Wilson',
  //     Thursday: 'Emily Brown',
  //     Friday: 'Alice Williams',
  //     Saturday: 'Jane Smith',
  //     Sunday: 'John Doe',
  //   },
  //   Evening: {
  //     Monday: 'Jane Smith',
  //     Tuesday: 'John Doe',
  //     Wednesday: 'Bob Johnson',
  //     Thursday: 'Chris Davis',
  //     Friday: 'Michael Wilson',
  //     Saturday: 'Alice Williams',
  //     Sunday: 'Emily Brown',
  //   },
  // },
  // ... (similar structures for Week 3 to Week 10)
];

const StaffTable = () => {
  return (
    <div className="staff-tables my-5">
      {weeklyData.map((week, weekIndex) => (
        <div key={weekIndex} className="staff-table">
          <div className="d-flex w-100 justify-content-between">
            <Typography variant='h6'>Shift Plan - {week.weekName}</Typography>
            <NavLink to="/">
              <div className="d-flex">
                <Typography variant='subtitle1' className='me-2'>Add Shift</Typography><PostAddIcon />
              </div>
            </NavLink>
          </div>
          <Divider />
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Monday</th>
                <th scope="col">Tuesday</th>
                <th scope="col">Wednesday</th>
                <th scope="col">Thursday</th>
                <th scope="col">Friday</th>
                <th scope="col">Saturday</th>
                <th scope="col">Sunday</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(week).map((shift, shiftIndex) => (
                // Skip weekName property
                shift !== 'weekName' && (
                  <tr key={`${weekIndex}-${shiftIndex}`}>
                    <th scope="row">{shift}</th>
                    {Object.values(week[shift]).map((employee, dayIndex) => (
                      <td key={`${weekIndex}-${shiftIndex}-${dayIndex}`}>{employee}</td>
                    ))}
                  </tr>
                )
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default StaffTable;
