import React, { PureComponent, useState, useEffect } from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
} from 'recharts';
import { FormControl, InputLabel, NativeSelect, Typography } from '@mui/material';
import axios from 'axios';

function getRandomDateIn2023() {
  const start = new Date('2023-01-01').getTime();
  const end = new Date('2023-12-31').getTime();
  const randomTime = start + Math.random() * (end - start);
  return new Date(randomTime).toISOString();
}

function getRandomPrice() {
  return Math.floor(Math.random() * 10000) + 1000; // Random price between 1000 and 10999
}

const getWeekNumber = (date) => {
  const oneJan = new Date(date.getFullYear(), 0, 1);
  const numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000));
  return Math.ceil((date.getDay() + 1 + numberOfDays) / 7);
};




export default function WeeklyStats() {
  const [data, setData] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(getWeekNumber(new Date()));
  const [expenseData, setExpenseData] = React.useState([])
  const [salesData, setSalesData] = React.useState([])



  const fetchExpense = () => {

    axios
      .get("https://api-dhejomel.azgard.co.ke/api/Expenses/GetAllExpenses?$select=Amount,date")
      .then((response) => {
        // console.log(response.data);
        return response.data.$values
      }).catch((e) => {
        console.log(e.response.message)
      });

  }

  const fetchSales = () => {

    axios
      .get("https://api-dhejomel.azgard.co.ke/api/Sales/GetAllSales?$select=Amount,date")
      .then((response) => {
        // console.log(response.data);
        return response.data.$values
      }).catch((e) => {
        console.log(e.response.message)
      });

  }



  const calculateRevenue = (salesData, expenseData) => {
    const revenueList = [];

    salesData.forEach((sale) => {
      const matchingExpense = expenseData.find((expense) => expense.Date === sale.Date);

      if (matchingExpense) {
        const revenue = {
          Date: sale.Date,
          Revenue: sale.Amount - matchingExpense.Amount,
        };

        revenueList.push(revenue);
      }
    });

    return revenueList;
  };

  const fetchRevenue = async () => {
    console.log("fetching revenue..");
    try {
      const salesResponse = await fetchSales();
      const expenseResponse = await fetchExpense();

      const salesData = salesResponse.$values;
      const expenseData = expenseResponse.$values;

      const revenueList = calculateRevenue(salesData, expenseData);

      console.log("Revenue List:", revenueList);
      return revenueList;
    } catch (error) {
      console.error(error.message);
    }
  };

  // Example usage



  useEffect(() => {
    fetchRevenue();
    const newDataset = [];

    for (let i = 0; i < 200; i++) {
      const newItem = {
        amount: getRandomPrice(),
        updatedAt: getRandomDateIn2023(),
      };
      newDataset.push(newItem);
    }

    const target = 5500;
    const weeklyTotal = [
      { day: 'Mon', revenue: 0, target: target },
      { day: 'Tue', revenue: 0, target: target },
      { day: 'Wed', revenue: 0, target: target },
      { day: 'Thu', revenue: 0, target: target },
      { day: 'Fri', revenue: 0, target: target },
      { day: 'Sat', revenue: 0, target: target },
      { day: 'Sun', revenue: 0, target: target },
    ];

    newDataset.forEach((item) => {
      const updatedAt = new Date(item.updatedAt);
      const weekNumber = getWeekNumber(updatedAt);

      if (weekNumber === selectedWeek) {
        const dayOfWeek = updatedAt.getDay(); // 0 for Sunday, 1 for Monday, and so on
        weeklyTotal[dayOfWeek].revenue += item.amount;
      }
    });

    setData(weeklyTotal);
  }, [selectedWeek]);

  const handleChangeWeek = (event) => {
    setSelectedWeek(parseInt(event.target.value));
  };

  const weekOptions = Array.from({ length: 52 }, (_, weekIndex) => ({
    value: weekIndex + 1,
    label: `Week ${weekIndex + 1}`,
  }));

  return (
    <>
      <div className="d-flex pt-2 justify-content-between">
        <Typography>Weekly Performance Data</Typography>

        <div className="d-flex">
          <div sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Select Week
              </InputLabel>
              <NativeSelect
                value={selectedWeek}
                onChange={handleChangeWeek}
                inputProps={{
                  name: 'week',
                  id: 'uncontrolled-native',
                }}
              >
                {weekOptions.map((weekOption) => (
                  <option key={weekOption.value} value={weekOption.value}>
                    {weekOption.label}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </div>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          width={500}
          height={450}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="day" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="revenue" barSize={20} fill="#8884d8" name="Revenue" />
          <Line type="monotone" dataKey="target" stroke="#ff7300" name="Target" />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
}
