import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import BackEndheader from '../components/partials/backHeader';
import Sales from '../pages/Sales/sales';
import { Container } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ExpenseTransactions from '../pages/Expense/transactions';
import Finance from '../pages/finance';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const FinanceRoutes = () => {
const [value, setValue] = React.useState(0);
const profile = JSON.parse(localStorage.getItem("DhejomelUser"))

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
   return(
    <div>
    {
        profile.role !== "standard" ? 
        <div>
            <Finance />
        </div>
        :
        <div style={{ backgroundColor: "whitesmoke", minHeight: "100vh" }}>
        <BackEndheader />
        <Container maxWidth="xl" className='mt-3' style={{marginBottom:'-10px'}}>
            <Box sx={{ width: '100%',marginBottom:'-20px' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Sales" {...a11yProps(0)} />
                    <Tab label="Expense" {...a11yProps(1)} />
                    {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <Sales />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <ExpenseTransactions />
                </CustomTabPanel>
                {/* <CustomTabPanel value={value} index={2}>
                    Item Three
                </CustomTabPanel> */}
            </Box>

            </Container>
        </div>
        }
    
        </div>
   )
};

export default FinanceRoutes;
