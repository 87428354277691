import React from 'react';
import { Chart } from 'react-google-charts';

const AssetsExpenseChart = () => {
  const data = [
    ['Asset', 'Expense %'],
    ['Consumables', 11],
    ['Furniture', 2],
    ['Electronics', 2],
    ['Motor Vehicles', 2],
    ['Others', 7],
  ];

  const options = {
    title: 'Asset Expense Summary',
    is3D: true,
  };

  return (
    <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width="100%"
      height="400px"
    />
  );
};

export default AssetsExpenseChart;
