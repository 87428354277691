import React from 'react'
import { Container, Divider, Paper, Typography } from '@mui/material'
import StaffTable from './staffTable'
import StaffPie from './staffPie'
import GroupsIcon from '@mui/icons-material/Groups';
import TableWithNamesAndRoles from './staffNamesTable';

const StaffSummary = () => {
  return (
    <div>
      <div className="d-flex justify-content-between ">
        <Paper elevation={0}
          style={{
            minHeight: "150px",
            width: "100%",
            backgroundColor: "#B7D1DA"
          }} >
          <div className="px-4 pt-4">
            <Typography variant='h6' gutterBottom className=''>
              All Employees
            </Typography>
            <Divider />
            <div className="d-flex">
              <GroupsIcon sx={{ fontSize: "32px" }} />
              <Typography variant='h5' gutterBottom className='ps-2'>
                20
              </Typography>
            </div>
          </div>
          <div className="d-flex">
            <div className="check bg-white me-2" style={{ width: "18px", height: "20px" }} />
            {/* <Typography variant='subtitle2'>Today</Typography> */}
          </div>
        </Paper>
        <Paper elevation={0} style={{
          height: "150px",
          width: "100%",
          backgroundColor: "#D2D5DD"
        }} className='mx-3 '>
          <div className="px-4 pt-4">
            <Typography variant='h6' gutterBottom className=''>
              Active Employees
            </Typography>
            <Divider />
            <div className="d-flex">
              <GroupsIcon sx={{ fontSize: "32px" }} />
              <Typography variant='h5' gutterBottom className='ps-2'>
                18
              </Typography>
            </div>

          </div>
          <div className="d-flex">
            <div className="check bg-white me-2" style={{ width: "18px", height: "20px" }} />
            {/* <Typography variant='subtitle2'>Today</Typography> */}
          </div>

        </Paper>
        <Paper elevation={0} style={{
          height: "150px",
          width: "100%",
          backgroundColor: "#9DD9D2"
        }} >
          <div className="px-4 pt-4">
            <Typography variant='h6' gutterBottom className=''>
              On Leave
            </Typography>
            <Divider />
            <div className="d-flex">
              <GroupsIcon sx={{ fontSize: "32px" }} />
              <Typography variant='h5' gutterBottom className='ps-2'>
                2
              </Typography>
            </div>
          </div>
          <div className="d-flex">
            <div className="check bg-white me-2" style={{ width: "18px", height: "20px" }} />
            {/* <Typography variant='subtitle2'>Today</Typography> */}
          </div>
        </Paper>
        <Paper elevation={0} style={{
          height: "150px",
          width: "100%",
          backgroundColor: "#C4D7F2"
        }} className='ms-3  '>
          <div className="px-4 pt-4">
            <Typography variant='h6' gutterBottom className=''>
              Ex Employees
            </Typography>
            <Divider />
            <div className="d-flex">
              <GroupsIcon sx={{ fontSize: "32px" }} />
              <Typography variant='h5' gutterBottom className='ps-2'>
                5
              </Typography>
            </div>
          </div>
          <div className="d-flex">
            <div className="check bg-white me-2" style={{ width: "18px", height: "20px" }} />
            {/* <Typography variant='subtitle2'>Today</Typography> */}
          </div>
        </Paper>

      </div>
      <>
        <StaffTable />
      </>
      <div className="d-flex">
        <div className="w-50 me-2">
          <Typography >
            Staff Members
          </Typography>
          <TableWithNamesAndRoles />
        </div>
        <div className="w-50 ms-2">
          <StaffPie />
        </div>
      </div>
    </div>
  )
}

export default StaffSummary