import React from 'react';
import { Chart } from 'react-google-charts';

const AssetsNumberCharts = () => {
  const data = [
    ['Consumables', 'Furniture', 'Electronics', 'Vehicles', 'Others'],
    ['Jan', 1000, 400, 500, 800],
    ['Feb', 1170, 460, 300, 600],
    ['Mar', 660, 1120, 1200, 1000],
    ['Apr', 1030, 540, 220, 530],
    ['May', 800, 300, 400, 700],
    ['Jun', 1200, 600, 700, 1100],
    ['Jul', 950, 350, 450, 900],
    ['Aug', 870, 420, 320, 610],
    ['Sep', 1100, 500, 600, 950],
    ['Oct', 930, 480, 350, 720],
    ['Nov', 780, 320, 420, 850],
    ['Dec', 1050, 550, 480, 780],
  ];

  const options = {
    title: 'Assets Aquisition Summary',
    hAxis: { title: 'Month', titleTextStyle: { color: '#333' } },
    vAxis: { minValue: 0 },
    chartArea: { width: '80%', height: '70%' },
    curveType: 'function', // Set this to 'function' for smooth lines
    series: {
      0: { pointShape: 'circle', pointSize: 8 }, // Series 0 (Sales)
      1: { pointShape: 'circle', pointSize: 8 }, // Series 1 (Expenses)
      2: { pointShape: 'circle', pointSize: 8 }, // Series 2 (Option1)
      3: { pointShape: 'circle', pointSize: 8 }, // Series 3 (Option2)
    },
    tooltip: { trigger: 'both' }, // Display tooltips on hover
    legend: { position: 'bottom' }, // Move legend to the bottom
  };

  return (
    <div className='bg-info'>
      <Chart
        chartType="LineChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    </div>
  );
};

export default AssetsNumberCharts;
