import { Divider, Typography } from '@mui/material'
import React from 'react'
import { ProgressBar } from 'react-bootstrap'

const StreamsStats = () => {
  return (
    <div>
      <Typography>
        Perfomance Statistics
      </Typography>
      <Typography variant='caption'>
        Conference Facility
      </Typography>
      <ProgressBar now={60} label={`${60}%`} />
      <div className='my-3' />
      <Typography variant='caption' >
        VIP Room
      </Typography>
      <ProgressBar now={20} label={`${20}%`} />
      <div className='my-3' />
      <Typography variant='caption' >
        Executive Room
      </Typography>
      <ProgressBar now={10} label={`${10}%`} />
      <div className='my-3' />
      <Typography variant='caption' >
        Superior Room
      </Typography>
      <ProgressBar now={10} label={`${10}%`} />
      <Typography className='mt-4'>
        Visitors Statistics
      </Typography>
      <Typography variant='caption' >
        Unique Visitors
      </Typography>
      <ProgressBar now={60} label={`${10}%`} variant='success' />
      <div className='my-2' />

      <Typography variant='caption' >
        Repeat Visitors
      </Typography>
      <ProgressBar now={50} label={`${10}%`} variant='warning' />

    </div>
  )
}

export default StreamsStats