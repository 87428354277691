import { LoadingButton } from '@mui/lab';
import { Alert, Button, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import SaveIcon from '@mui/icons-material/Save';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
import CancelIcon from '@mui/icons-material/Cancel';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

const RoomManagerModel = (props) => {
  const [roomType, setRoomType] = useState(null)
  const [roomId, setRoomId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [roomDetails, setRoomDetails] = useState(null)
  const [categoryPrices, setCategoryPrices] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showEditImages, setShowEditImages] = useState(false);
  const [showEditPrices, setShowEditPrices] = useState(false);

  const handleCategoryPriceChange = (categoryId, value) => {
    setCategoryPrices((prevPrices) => ({
      ...prevPrices,
      [categoryId]: value,
    }));
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };

  const handleUpload = () => {
    setIsUploading(true)
    setIsLoading(true)
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('roomImages', file);
    });

    // Make the API call to the endpoint that accepts multiple images
    fetch(`https://api-dhejomel.azgard.co.ke/api/RoomService/AddRoomImages?roomId=${roomId}`, {
      method: 'POST',
      body: formData,
    })
      .then((data) => {
        console.log(data.response);
        // Handle the response from the server
        setIsUploading(false)
        GetRoomById(roomId)
        setSelectedFiles([])
      })
      .catch((error) => {
        // Handle any error that occurs during the upload
        console.error(error);
      });
  };

  const AddRoom = (roomType) => {
    setIsLoading(true)
    const { v4: uuidv4 } = require('uuid');
    const _roomId = uuidv4();

    const payload = {
      "roomId": _roomId,
      "roomType": roomType
    }
    setShowEditImages(true)
    setShowEditPrices(true)
    axios
      .post(`https://api-dhejomel.azgard.co.ke/api/RoomService/AddRoomType`, payload,)
      .then((response) => {
        GetRoomById(_roomId)
      }).catch((e) => {
        console.log(e.response?.message)
      });
  }

  const GetRoomById = (roomId) => {
    setIsLoading(true)
    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/RoomService/GetRoomById?id=${roomId}`)
      .then((response) => {
        setRoomDetails(response.data)
        setTimeout(() => {
          setRoomId(roomId)
          setIsLoading(false)// Set loading state to false after 3 seconds
        }, 1000);

        console.log(response.data);
      }).catch((e) => {
        console.log(e.response?.message)
      });
  }

  const UpdateRoomPrice = (newPrice, id) => {
    const payload = {
      id: id,
      categoryPrice: newPrice
    }


    axios
      .put(`https://api-dhejomel.azgard.co.ke/api/RoomService/UpdateRoomPrice`, payload,)
      .then((response) => {
        GetRoomById(roomId)
      }).catch((e) => {
        console.log(e.response?.message)
      });
  }

  const DeleteRoomImage = (id) => {
    setIsLoading(true)
    axios
      .delete(`https://api-dhejomel.azgard.co.ke/api/RoomService/DeleteRoomImage?id=${id}`,)
      .then((response) => {
        GetRoomById(roomId)
        setIsLoading(false)

      }).catch((e) => {
        console.log(e.response?.message)
      });
  }

  React.useEffect(() => {
    setIsLoading(false)
    setIsUploading(false)
  }, [props.show])

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
            Add New Room
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "500px" }}>
          <Typography variant='subtitle1' className='my-2'>Define Room Type eg. VIP</Typography>
          <TextField
            id="outlined-basic"
            label="Room Type eg. VIP"
            variant="outlined"
            className='w-100'
            disabled={roomId !== null}
            value={roomType}
            onChange={(e) => setRoomType(e.target.value)}
          />

          {
            roomId !== null &&
            <>

              <>
                <Typography variant='subtitle1' className='mt-4'>Define Room Prices</Typography>
                {
                  roomDetails?.category.$values?.map((item, key) =>
                    <TextField
                      label={item?.categoryName}
                      id="outlined-start-adornment"
                      className='mt-3 w-100'
                      value={categoryPrices[item?.id] || ''}
                      onChange={(e) =>
                        handleCategoryPriceChange(item?.id, e.target.value)
                      }
                      onBlur={(e) => UpdateRoomPrice(e.target.value, item?.id)}
                      color="success"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">KES</InputAdornment>,
                      }}
                    />

                  )
                }

              </>


              <Typography variant='subtitle1' className='mt-4'>Define Room Images</Typography>
              <div class="mb-3">
                <label for="formFileMultiple" class="form-label">You can select multiple Photos</label>
                <input class="form-control" type="file" id="formFileMultiple" multiple onChange={handleFileChange} accept="image/*" />
              </div>
              {
                isUploading ?
                  <LoadingButton
                    color="secondary"
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                  >
                    <span>Uploading...</span>
                  </LoadingButton>
                  :
                  <Button
                    variant="contained"
                    onClick={handleUpload}
                  >Upload images</Button>

              }
              <div className="d-flex w-100 flex-grow-1">
                {
                  roomDetails?.images.$values?.map((item, key) =>
                    <div className="w-25 m-2 mt-3" key={key} >
                      <div className="d-flex justify-content-end position-relative"
                        style={{
                          marginBottom: '-8px',
                          marginRight: "-14px",
                          zIndex: 100,
                          cursor: 'pointer'
                        }}
                        onClick={() => DeleteRoomImage(item.id)}
                      >
                        <CancelIcon style={{ color: "red" }} />
                      </div>
                      <img src={item.imageUrl} alt="" srcset="" className='img-fluid me-2' />
                    </div>
                  )
                }
              </div>

            </>
          }

        </Modal.Body>



        <Modal.Footer>
          {
            isLoading ?
              <LoadingButton
                color="secondary"
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
              >
                <span>Processing..</span>
              </LoadingButton>
              :
              <>
                {
                  roomId === null ?
                    <Button
                      variant="contained"
                      onClick={() => AddRoom(roomType)}
                    >Submit</Button>
                    :
                    <Button
                      variant="contained"
                      onClick={() => {
                        setRoomDetails(null)
                        setCategoryPrices(null)
                        setSelectedFiles(null)
                        setRoomType(null)
                        setRoomId(null)
                        props.onHide()
                      }}
                    >Exit</Button>
                }
              </>

          }

        </Modal.Footer>
      </Modal>


    </div>
  )
}

export default RoomManagerModel

