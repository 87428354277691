import React from 'react'
import Header from '../../components/partials/header'
import Footer from '../../components/partials/footer'
import FsLightbox from 'fslightbox-react'
import VIP from '../../assets/images/rooms/VIP Room.jpeg'
import Executive from '../../assets/images/rooms/executive.jpeg'
import Superior from '../../assets/images/rooms/VIP.jpeg'
import axios from 'axios'
import { Backdrop, CircularProgress } from '@mui/material'

const Rooms = () => {
  const [toggler, setToggler] = React.useState(false);
  const [theRooms, setTheRooms] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [roomImages, setRoomImages] = React.useState(['https://www.boutiques.marriottbonvoy.com/wp-content/uploads/2019/10/whotels-bed.jpg',
    'https://cdn.shopify.com/s/files/1/0042/0626/4384/files/shutterstock_1017013843_large.jpg',
    'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    'https://media.cnn.com/api/v1/images/stellar/prod/180117182508-aka-wall-street.jpg?q=w_1600,h_900,x_0,y_0,c_fill/w_1280']);

  const GetRooms = () => {
    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/RoomService/GetRooms`)
      .then((response) => {
        setTheRooms(response.data.$values)
        console.log(response.data);
      }).catch((e) => {
        console.log(e.response?.message)
      });
  }

  const GetRoomImages = (id) => {
    setIsLoading(true)
    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/RoomService/GetImagesById?roomId=${id}`)
      .then((response) => {

        var theImages = []
        response.data.map((item) =>
          theImages.push(item.imageUrl)
        )

        setRoomImages(theImages)
        setToggler(!toggler)

        setIsLoading(false)

        console.log("room images", roomImages);
      }).catch((e) => {
        console.log(e.response?.message)
      });
  }


  React.useEffect(() => {
    GetRooms()
  }, [])
  return (
    <div>
      <Header />
      <main>
        {
          isLoading &&
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }

        <FsLightbox
          toggler={toggler}
          sources={roomImages}
        />
        <div class="slider-area">
          <div class="single-slider hero-overly slider-height2 d-flex align-items-center"
            style={{ backgroundImage: "url('https://new-hls.s3.amazonaws.com/hls/data/1608/website/general/bn/banner3.jpg')" }}
          >
            <div class="container">
              <div class="row ">
                <div class="col-md-11 offset-xl-1 offset-lg-1 offset-md-1">
                  <div class="hero-caption">
                    <span>Rooms</span>
                    <h2>Our Rooms</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <section class="room-area r-padding1">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-8">

                <div class="font-back-tittle mb-45">
                  <div class="archivment-front">
                    <h3>Our Rooms</h3>
                  </div>
                  <h3 class="archivment-back">Our Rooms</h3>
                </div>
              </div>
            </div>
            <div class="row" style={{ cursor: "pointer" }}>
              {
                theRooms?.map((item, key) =>
                  <div class="col-xl-4 col-lg-6 col-md-6" key={key}>
                    <div
                      class="single-room mb-50"
                      onClick={() => {
                        GetRoomImages(item.id)
                      }}
                    >
                      <div class="room-img">
                        <img src={item?.images.$values[0]?.imageUrl} alt="" />
                      </div>
                      <div class="room-caption">
                        <h3>{item.roomType}</h3>
                        {
                          item?.category?.$values.map((item, key) =>
                            <div class="per-night d-flex flex-column">
                              <span>Ksh.{item.categoryPrice}  <span>/ {item.categoryName}</span> </span>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                )

              }

            </div>

          </div>
        </section>


        <div class="gallery-area fix">
          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-md-12">
                <div class="gallery-active owl-carousel">
                  <div class="gallery-img">
                    <a href="#"><img src="assets/img/gallery/gallery1.jpg" alt="" /></a>
                  </div>
                  <div class="gallery-img">
                    <a href="#"><img src="assets/img/gallery/gallery2.jpg" alt="" /></a>
                  </div>
                  <div class="gallery-img">
                    <a href="#"><img src="assets/img/gallery/gallery3.jpg" alt="" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
      <Footer />
    </div>
  )
}

export default Rooms