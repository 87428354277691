import React from 'react'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import HomeIcon from '@mui/icons-material/Home';
import BookRoom from '../bookRoom';

function Header() {
  const [bookingShow, setBookingShow] = React.useState(false);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const navLinks = [
    {
      name: 'Our Rooms',
      link: '/rooms',
      icon: <BedroomParentIcon />
    }, {
      name: 'Our Services',
      link: '/services',
      icon: <HomeRepairServiceIcon />

    }, {
      name: 'Contact Us',
      link: '/contact',
      icon: <ContactMailIcon />

    },
  ]


  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Typography variant='h5' className='p-3'><strong>Dhe Jomels Hotel</strong></Typography>
      <List>
        <ListItem disablePadding>
          <a href='/' style={{ textDecoration: 'none' }}>
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary='Home' style={{ color: "rgb(128, 0, 0)" }} />
            </ListItemButton>
          </a>
        </ListItem>
        {navLinks.map((item, key) => (
          <ListItem key={key} disablePadding>
            <NavLink to={item.link} style={{ textDecoration: 'none' }}>
              <ListItemButton>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} style={{ color: "rgb(128, 0, 0)" }} />
              </ListItemButton>
            </NavLink>
          </ListItem>
        ))}
      </List>
      <Divider />

    </Box>
  );
  return (
    <header className='sticky-top'>

      <div className="slider m-0 d-none d-md-flex">
        <marquee width="100%" direction="left" style={{ backgroundColor: "#800000", color: "white" }}>
          <p style={{ padding: 0, margin: 0, color: "white" }} className='py-1'>
            <strong className='p-0 m-0'>
              Welcome to Dhe Jomels Hotel, Your Home away from Home. Call +254706452721 to talk to our customer service representative or email booking@dhejomelshotel.co.ke
            </strong>
          </p>
        </marquee>
      </div>
      <div className="d-md-none mt-0 pt-0 ">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={{ backgroundColor: "rgb(128, 0, 0)" }}>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={toggleDrawer('left', true)}              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Dhe Jomels Hotel
              </Typography>
              <a href="tel:+254706452721" className='text-white'><Button color="inherit">Call Us</Button></a>

            </Toolbar>
          </AppBar>
        </Box>
      </div>
      <div class="header-area header-sticky d-none d-md-block">
        <div class="main-header d-md-flex">
          <div class="container">
            <div class="row align-items-center">

              <div class="col-xl-3 col-lg-3">
                <div class="logo d-none d-md-flex">
                  {/* <!-- <a href="#"><img src="assets/img/logo/logo.png" alt=""></a> --> */}
                  <h2>Dhe Jomels Hotel</h2>
                </div>
              </div>
              <div class="col-xl-7 col-lg-7">

                <div class="main-menu f-right d-none d-lg-block ">
                  <nav>
                    <ul id="navigation" className='d-flex align-items-center justify-content-center '>
                      <NavLink exact to="/staff" activeClassName="active">

                      </NavLink>
                      <li>

                        <a href="/" style={{ textDecoration: 'none' }}>Home</a>
                        <a href="/home" style={{ textDecoration: 'none', display: 'none' }}>Home</a>
                      </li>
                      <li>
                        <NavLink exact to="/rooms" activeClassName="active" style={{ textDecoration: 'none' }}>
                          Rooms
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/services" activeClassName="active" style={{ textDecoration: 'none' }}>
                          Our Services
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/contact" activeClassName="active" style={{ textDecoration: 'none' }}>
                          Contacts
                        </NavLink>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="col-xl-2 col-lg-2">

                <div class=" ">
                  <a onClick={() => setBookingShow(true)} class="btn btn1 d-none d-lg-block " style={{ backgroundColor: "rgb(128, 0, 0)", color: "white", fontWeight: "bold" }}>Book Online</a>
                </div>
              </div>


            </div>
          </div>
        </div>

      </div>
      <div className='w-100'>

        <Drawer
          anchor={'left'}
          open={state['left']}
          onClose={toggleDrawer('left', false)}
          className='w-100'
        >
          {list('left')}
        </Drawer>
      </div>

      <BookRoom
        show={bookingShow}
        onHide={() => {
          setBookingShow(false)
        }}
      />
    </header>
  )
}

export default Header