import { Button, Divider } from '@mui/material'
import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import PersonOutlineSharpIcon from '@mui/icons-material/PersonOutlineSharp';
import { Outlet, Link } from "react-router-dom";
const BackEndheader = () => {

  const pages = [
    {
      name: 'Rooms',
      route: '/staff'
    },
    {
      name: 'Finance',
      route: '/finance'
    }, {
      name: 'Assets',
      route: '/assets'
    },

  ];

  const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [loggedInUser, setLoggedInUser] = React.useState(localStorage.getItem("DhejomelUser"));

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const linkStyle = {
    textDecoration: "none",
    fontWeight: "bold",
  }


  React.useEffect(() => {
    var user = JSON.parse(localStorage.getItem("DhejomelUser"))
    setLoggedInUser(user)
  }, [])

  return (
    <AppBar position="sticky" style={{ margin: 0, backgroundColor: "rgb(128, 0, 0)", zIndex: 1 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Dhe Jomels  Admin
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'd-flex' },
              }}
            >
              {pages.map((page, key) => (
                <MenuItem key={key} onClick={handleCloseNavMenu}>
                  <Link
                    to={page.route}
                    style={linkStyle}

                  >
                    <Typography
                      textAlign="center"
                      variant='h6'
                    >{page.name}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
            className='justify-content-end me-5'>
            {pages.map((page, key) => (
              <Link
                to={page.route}
                style={linkStyle}
                activeClassName="active-link"
              >
                <Button
                  key={key}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block', fontWeight: 'bold' }}
                >
                  {page.name}
                </Button>
              </Link>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings" className=''>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar style={{ backgroundColor: '#FFD700' }}> <PersonOutlineSharpIcon
                  className='text-dark'
                  sx={{ Backgroundcolor: 'rgb(128, 0, 0' }}
                /></Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <div className="d-flex flex-column ms-3">
            <Typography
              variant='subtitle2'
              style={{ color: '#FFD700', margin: 0, padding: 0 }}
              className='p-0 m-0'>
              Administrator
            </Typography>
            <Typography
              variant='caption'
              className='p-0 m-0'
              sx={{ margin: 0, padding: 0 }}>
              {loggedInUser?.firstName} {loggedInUser?.lastName}
            </Typography>
          </div>
          {/* <Button
              variant="outlined"
              className='my-3 ms-3'
              onClick={() => handleLogout()}
              style={{
                backgroundColor: "#FFD700",
                color: "rgb(128, 0, 0)",
                border: "0px"
              }}
            >
              <div className="d-flex align-items-center">
                <div className="me-2">Logout <LogoutIcon /></div>
              </div>
            </Button> */}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default BackEndheader