import { Alert, Button, TextField, Typography } from '@mui/material';
import React from 'react'
import Modal from 'react-bootstrap/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import axios from 'axios';
import { Carousel, Col, Row } from 'react-bootstrap';
import Swal from 'sweetalert2'
import { format, parse } from 'date-fns';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import VIP from '../assets/images/rooms/VIP Room.jpeg'
import Executive from '../assets/images/rooms/executive.jpeg'
import Superior from '../assets/images/rooms/VIP.jpeg'
import { LineWave, ThreeDots } from 'react-loader-spinner';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const BookRoom = (props) => {
  const [fullName, setFullName] = React.useState('');
  const [roomType, setRoomType] = React.useState({
    type: 'VIP Room (Bed and Breakfast)',
    price: 'KES 8,500',
    category: '(Bed and Breakfast)',
    room: 'VIP',
    images: [VIP]
  });
  const [roomNumber, setRoomNumber] = React.useState(1);
  const [roomAvailability, setRoomAvailability] = React.useState('');
  const [adults, setAdults] = React.useState(1);
  const [children, setChildren] = React.useState(0);
  const [checkInDate, setCheckInDate] = React.useState(dayjs(Date.now()));
  const [checkoutDate, setCheckOutDate] = React.useState(dayjs(Date.now()));
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [paymentOption, setPaymentOption] = React.useState('Reserve');
  const [paymentMode, setPaymentMode] = React.useState('Cash');
  const [email, setEmail] = React.useState('');
  const [nationality, setNationality] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [paymentShow, setPaymentShow] = React.useState(false);
  const [processpayment, setProcessPayment] = React.useState(false);
  const [theRooms, setTheRooms] = React.useState([]);

  const success = () => {
    Swal.fire(
      'Success!',
      'Room Booked Successfully!',
      'success'
    )
  }


  const GetRooms = () => {
    console.log('Getting rooms');
    setIsLoading(true)
    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/RoomService/GetCategories`)
      .then((response) => {
        setTheRooms(response.data.$values)
        console.log(response.data.$values);
        console.log('success Getting rooms');

        setRoomType({
          type: response.data.$values[0].room.roomType + " Room (" + response.data[0].categoryName + ")",
          price: response.data.$values[0].categoryPrice,
          images: response.data.$values[0].room.images,
          category: response.data.$values[0].categoryName,
          room: response.data.$values[0].room.roomType
        })
        setIsLoading(false)

      }).catch((e) => {
        console.log(e.response?.message)
      });
  }

  const GetRoomsByNumberOfAdults = (adults) => {
    setTheRooms([])
    setIsLoading(true)
    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/RoomService/GetCategoriesByAdults?numberOfAdults=${adults}`)
      .then((response) => {
        setTheRooms(response.data.$values)
        console.log(response.data.$values);
        console.log('success Getting rooms');

        setRoomType({
          type: response.data.$values[0].room.roomType + " Room (" + response.data.$values[0].categoryName + ")",
          price: response.data.$values[0].categoryPrice,
          images: response.data.$values[0].room.images,
          category: response.data.$values[0].categoryName,
          room: response.data.$values[0].room.roomType
        })
        setIsLoading(false)

      }).catch((e) => {
        setIsLoading(false)

        console.log(e.response?.message)
      });
  }


  const bookRoom = () => {
    setLoading(true)
    const dateObjectOne = parse(checkInDate, "EEE, dd MMM yyyy HH:mm:ss 'GMT'", new Date());
    const formattedDateOne = format(dateObjectOne, "yyyy-MM-dd");

    const dateObjectTwo = parse(checkoutDate, "EEE, dd MMM yyyy HH:mm:ss 'GMT'", new Date());
    const formattedDateTwo = format(dateObjectTwo, "yyyy-MM-dd");

    const payload = {
      fullName: fullName,
      emailAddress: email,
      nationality: nationality,
      checkInDate: formattedDateOne,
      checkOutDate: formattedDateTwo,
      roomType: roomType.room,
      numberOfRooms: parseInt(roomNumber),
      numberOfAdults: parseInt(adults),
      numberOfChildren: parseInt(children),
      isReserved: true,
      isPaid: false,
      isCancelled: false,
      phoneNumber: phoneNumber,
      serviceCategory: roomType.category,
      price: roomType.price,
      paymentOption: paymentOption
    };

    axios
      .post("https://api-dhejomel.azgard.co.ke/api/Booking/AddBooking", payload)
      .then((response) => {
        success()
        setLoading(false)
        setIsSubmitted(true)
        props.onHide()
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const checkAvailability = (checkInDate, roomType) => {
    setIsLoading(true)
    const dateObjectOne = parse(checkInDate, "EEE, dd MMM yyyy HH:mm:ss 'GMT'", new Date());
    const formattedDateOne = format(dateObjectOne, "yyyy-MM-dd");

    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/Booking/CheckRoomAvailability?date=${formattedDateOne}&roomType=${roomType}`)
      .then((response) => {
        setRoomAvailability(response.data)
        console.log(response.data);

        setTimeout(() => {
          setIsLoading(false)// Set loading state to false after 3 seconds
        }, 1000);

      }).catch((e) => {
        console.log(e.response.message)
      });
  }

  React.useEffect(() => {

    // GetRooms()
  }, [props.show])


  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
            Book A Room
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="row col-lg-12">
            <Col lg={6} sm={12}>



              <TextField
                id="outlined-basic"
                label="Full Name"
                variant="outlined"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                className='mb-3 w-100'
              />

              <TextField
                id="outlined-basic"
                label="Phone Number"
                variant="outlined"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className='mb-3 w-100'
              />

              <TextField
                id="outlined-basic"
                label="Email Address"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='mb-3 w-100'
              />

              <TextField
                id="outlined-basic"
                label="Nationality"
                variant="outlined"
                value={nationality}
                onChange={(e) => setNationality(e.target.value)}
                className='mb-3 w-100'
              />

              <TextField
                id="outlined-basic"
                label="Number of Adults"
                variant="outlined"
                type='number'
                value={adults}
                onChange={(e) => {
                  setAdults(e.target.value)
                  GetRoomsByNumberOfAdults(e.target.value)
                }}
                className='mb-3 w-100'
              />

              <FormControl sx={{ minWidth: 120 }}
                className='mb-3 w-100'

              >
                <InputLabel
                  id="demo-simple-select-helper-label">{roomType.type}</InputLabel>
                <Select

                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={roomType.type}
                  onChange={(e) => {
                    setRoomType(e.target.value)
                    checkAvailability(checkInDate, roomType.room)
                  }}
                  label={roomType.type}
                  className='mb-0 w-100'

                >
                  <MenuItem value={roomType.type}>
                    {
                      theRooms.length > 0 ? roomType.type + " - " + roomType.price : "Select Room Type"
                    }
                  </MenuItem>


                  {
                    theRooms?.map((item, key) =>
                      <MenuItem
                        value={{
                          type: item.room.roomType + " Room (" + item.categoryName + ")",
                          price: item.categoryPrice,
                          images: item.room.images,
                          category: item.categoryName,
                          room: item.room.roomType
                        }}
                        selected={roomType.type === item.room.roomType}>
                        {
                          item.room.roomType + " Room (" + item.categoryName + ")" + "-" + item.categoryPrice
                        }
                      </MenuItem>
                    )
                  }

                </Select>
              </FormControl>
              {
                roomAvailability !== '' && <>
                  {
                    isLoading ?
                      <>
                        <div className="d-flex  justify-content-center">
                          <ThreeDots
                            height="50"
                            width="80"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />

                        </div>
                      </> :
                      <Alert severity={`${roomAvailability ? "success" : "error"}`}>
                        {roomAvailability ? roomType.type + " room is available" : roomType.type + " are Full!"}
                      </Alert>
                  }
                </>
              }
            </Col>

            <Col lg={6} sm={12}>

              <div className="">
                <LocalizationProvider dateAdapter={AdapterDayjs}  >
                  <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                      label="Check In "
                      value={checkInDate}
                      onChange={(e) => {
                        setCheckInDate(e, "yyyy-MM-dd")
                      }}
                      className='w-100'
                      disablePast // Disable past dates
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                    label="Check Out"
                    value={checkoutDate}
                    onChange={(e) => setCheckOutDate(e, "yyyy-MM-dd")}
                    className='w-100 my-3'
                    disablePast // Disable past dates
                    renderInput={(params) => <TextField {...params} />}
                  />
                </DemoContainer>
              </LocalizationProvider>

              <FormControl className='mt-3 w-100'
              >
                <FormLabel id="demo-radio-buttons-group-label">Payment Mode</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Cash"
                  name="radio-buttons-group"
                  value={paymentMode}
                  onChange={(e) => setPaymentMode(e.target.value)}
                  className='d-flex'
                >
                  {/* <FormControlLabel value="Mpesa" control={<Radio />} label="MPesa" onClick={() => setProcessPayment(true)} /> */}
                  <FormControlLabel
                    value="Cash"
                    control={<Radio />}
                    onClick={() => {
                      setProcessPayment(false)
                      setPaymentOption("Cash")
                    }}
                    label="Cash"
                  />

                  <FormControlLabel
                    value="MPESA"
                    control={<Radio />}
                    onClick={() => {
                      setProcessPayment(false)
                      setPaymentOption("Mpesa")
                    }}
                    label="Mpesa"
                  />

                  <FormControlLabel
                    value="Reserve"
                    control={<Radio />}
                    onClick={() => {
                      setProcessPayment(false)
                      setPaymentOption("Reservation")
                    }}
                    label="Make Reservation"
                  />
                </RadioGroup>
              </FormControl>
            </Col>


          </Row >
        </Modal.Body>
        <Modal.Footer>
          {
            loading ?
              <LoadingButton
                color="secondary"
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                disabled
              >
                <span>Processing..</span>
              </LoadingButton>
              :
              <>
                {
                  processpayment ?
                    <Modal.Footer>
                      <Button variant="contained" onClick={() => setPaymentShow(true)} className='bg-success'>Process Payment</Button>
                    </Modal.Footer>
                    :
                    <Modal.Footer>
                      <Button
                        variant="contained"
                        className='d-none d-md-flex '
                        onClick={() => bookRoom()}
                        disabled={!roomAvailability}
                      >Confirm
                      </Button>
                      <Button
                        variant="contained"
                        className='d-md-none w-100 '
                        style={{ backgroundColor: "rgb(128, 0, 0)" }}
                        onClick={() => bookRoom()}
                        disabled={!roomAvailability}
                      >Confirm
                      </Button>
                    </Modal.Footer>
                }
              </>
          }
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default BookRoom

//58K i7
// 46K