import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import AssignmentDetails from './Details/assignmentDetails';

const AssetData = (props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columns = [
    {
      name: 'Asset Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Tag No.',
      selector: row => row.tagNumber,
    },
    {
      name: 'Supplier',
      selector: row => row.supplier,
    },
    {
      name: 'Delivery Date ',
      selector: row => moment(row.deliveryDate).format('lll'),
      sortable: true,
    },
    {
      name: 'Price',
      selector: row => row.price,
    },
  ];

  return (
    <Box sx={{ width: '100%', background: 'white', padding: '25px' }} >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="All Assets" {...a11yProps(0)} />
          <Tab label="Depreciated" {...a11yProps(1)} />
          <Tab label="Broken" {...a11yProps(2)} />
          <Tab label="Lost" {...a11yProps(3)} />
          <Tab label="Staff Assigned" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <div className="d-flex justify-content-end my-3 ">
        <OutlinedInput
          id="outlined-adornment-weight"
          endAdornment={<InputAdornment position="end" ><SearchIcon /></InputAdornment>}
          aria-describedby="outlined-weight-helper-text"
          size='small'
          inputProps={{
            'aria-label': 'weight',
          }}
        />
      </div>
      <div role="tabpanel" hidden={value !== 0} className='my-3'>
        {value === 0 && (
          <DataTable
            columns={columns}
            data={props.data}
            pagination
            highlightOnHover
            pointerOnHover
            customStyles={{
              headRow: {
                style: {
                  background: 'rgb(255, 215, 0,0.5)',
                  color: "dark"
                },
              },
              pagination: {
                style: {
                  background: 'rgb(255, 215, 0,0.3)',
                  color: "dark"
                },
              },
            }}
          />
        )}
      </div>
      <div role="tabpanel" hidden={value !== 1}>
        {value === 1 && (
          <DataTable
            columns={columns}
            data={props.data}
            pagination
            highlightOnHover
            pointerOnHover
            customStyles={{
              headRow: {
                style: {
                  background: 'rgb(255, 215, 0,0.5)',
                  color: "dark"
                },
              },
              pagination: {
                style: {
                  background: 'rgb(255, 215, 0,0.3)',
                  color: "dark"
                },
              },
            }}
          />
        )}
      </div>
      <div role="tabpanel" hidden={value !== 2}>
        {value === 2 && (
          <DataTable
            columns={columns}
            data={props.data}
            pagination
            highlightOnHover
            pointerOnHover
            customStyles={{
              headRow: {
                style: {
                  background: 'rgb(255, 215, 0,0.5)',
                  color: "dark"
                },
              },
              pagination: {
                style: {
                  background: 'rgb(255, 215, 0,0.3)',
                  color: "dark"
                },
              },
            }}
          />
        )}
      </div>
      <div role="tabpanel" hidden={value !== 3}>
        {value === 3 && (
          <DataTable
            columns={columns}
            data={props.data}
            pagination
            highlightOnHover
            pointerOnHover
            customStyles={{
              headRow: {
                style: {
                  background: 'rgb(255, 215, 0,0.5)',
                  color: "dark"
                },
              },
              pagination: {
                style: {
                  background: 'rgb(255, 215, 0,0.3)',
                  color: "dark"
                },
              },
            }}
          />
        )}
      </div>
      <div role="tabpanel" hidden={value !== 4}>
        {value === 4 && (
          <AssignmentDetails />
        )}
      </div>
    </Box>
  );
};

AssetData.propTypes = {
  data: PropTypes.array.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default AssetData;
