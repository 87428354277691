import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Chip from '@mui/material/Chip';

export default function AssetStaffAssignment() {

  const staff = [
    {
      name: 'Ali Connors',
      number: 2
    }, {
      name: 'John Okumu',
      number: 4
    }, {
      name: 'Samuel Mwangi',
      number: 2
    }, {
      name: 'Alice Wahome',
      number: 8
    }, {
      name: 'GK Nyambura',
      number: 3
    }
  ]

  return (
    <div className="w-100 bg-white">
      <Typography variant='subtitle2' className='ps-5 pt-4'>
        Staff Assigments
      </Typography>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {
          staff.map((item, key) =>
            <div key={key}>
              <ListItem
                alignItems="center"

              >
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                  primary={item.name}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {item.number}
                      </Typography>
                      {" — Assets"}
                      <Chip
                        label="Active"
                        color='success'
                        size='small'
                        className='ms-2  '
                      />

                    </React.Fragment>
                  }
                />
                <ArrowForwardIosIcon sx={{ alignSelf: 'center', cursor: 'pointer' }} />
              </ListItem>
              <Divider variant="inset" component="li" />
            </div>
          )
        }
      </List>
    </div>
  );
}
