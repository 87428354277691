import React from 'react'
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Swal from 'sweetalert2';
import axios from 'axios';
import BookingDetails from '../Details/bookingDetails';

function ConferenceDataSetup(props) {
  const [showConferenceDetails, setShowConferenceDetails] = React.useState(false);
  const [rowId, setRowId] = React.useState();

  const columns = [
    {
      name: 'FullName',
      selector: row => row.fullName,
      sortable: true,
    },
    {
      name: 'Phone Number',
      selector: row => (row.phoneNumber === "") ? "-" : row.phoneNumber,
    },
    {
      name: 'Email',
      selector: row => row.email,
    }, {
      name: 'Check In ',
      selector: row => moment(row.checkInDate).format('ll'),
      sortable: true,
    }, {
      name: 'Check Out ',
      selector: row => moment(row.checkOutDate).format('ll'),
      sortable: true,
    }, {
      name: 'Payment Option ',
      selector: row => 'Invoice',
      sortable: true,
    },



  ];


  return (
    <div>
      <DataTable
        columns={columns}
        data={props.data}
        pagination
        highlightOnHover
        pointerOnHover
        onRowClicked={(row, event) => {
          setRowId(row.id)
          setShowConferenceDetails(true)
        }}
        customStyles={{
          headRow: {
            style: {
              background: 'rgb(255, 215, 0,0.5)',
              color: "dark"
            },
          },
          pagination: {
            style: {
              background: 'rgb(255, 215, 0,0.3)',
              color: "dark"
            },
          },
        }}
      />

      <BookingDetails
        show={showConferenceDetails}
        onHide={() => setShowConferenceDetails(false)}
        rowId={rowId}

      />
    </div>

  )
}

export default ConferenceDataSetup