import React, { useState } from 'react'
import BackEndheader from '../../components/partials/backHeader'
import { Button, Divider } from '@mui/material'
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import StaffList from './list';
import ShiftPlan from './shiftPlan';
import NewShift from './newShift';
import AddStaff from './addStaff';
import StaffSummary from './components/staffSummary';

const StaffDashboard = () => {

  const [showAsset, setShowAsset] = useState(true);

  return (
    <div>
      <BackEndheader />
      <div className="d-flex" style={{ backgroundColor: 'whitesmoke' }}>
        <div
          className=" d-flex flex-column justify-content-between  tabs"
          style={{
            paddingTop: "60px",
            backgroundColor: "rgb(128, 0, 0,0.9)",
            minHeight: '100vh',
            width: '14vw'
          }}>
          <ul style={{ listStyle: "none", }} className=' m-0 p-0 px-2 '>
            <li
              className={`p-2  `}
              style={{
                backgroundColor: 'orange',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={() => setShowAsset(true)}

            >
              <div className="d-flex align-items-center">
                {/* <KingBedIcon className='me-1' /> */}
                <Typography variant="h6" className=" p-0 m-0 text-center" >
                  Dashboard
                </Typography>
              </div>
            </li>
            <li
              className={`p-2 my-2 `}
              style={{
                backgroundColor: 'orange',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={() => setShowAsset(false)}
            >
              <div className="d-flex align-items-center">
                {/* <KingBedIcon className='me-1' /> */}
                <Typography variant="h6" className=" p-0 m-0 text-center" >All Staff</Typography>
              </div>
            </li>
            <li
              className={`p-2 my-2 `}
              style={{
                backgroundColor: 'orange',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={() => setShowAsset(false)}
            >
              <div className="d-flex align-items-center">
                {/* <KingBedIcon className='me-1' /> */}
                <Typography variant="h6" className=" p-0 m-0 text-center" >History</Typography>
              </div>
            </li>
          </ul>
          <Button
            variant="outlined"
            className=' mx-2 '
            onClick={() => {
              // handleLogout()
            }}
            style={{
              backgroundColor: "#FFD700",
              color: 'black',
              border: "0px",
              marginBottom: '100px'
            }}
          >
            <div className="d-flex align-items-center">
              <div className="me-2 py-1">Logout <LogoutIcon /></div>
            </div>
          </Button>
        </div>
        <div className='m-3 w-100 bg-white p-4'>
          <StaffSummary />
          {/* <StaffList /> */}
          {/* <ShiftPlan /> */}
          {/* <NewShift /> */}
          {/* <AddStaff /> */}
        </div>
      </div>
    </div>
  )
}

export default StaffDashboard