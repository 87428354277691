import React from 'react';
import { Chart } from 'react-google-charts';

const AssetsTypeChart = () => {
  const data = [
    [
      'Asset',
      'Number',
      { role: 'style' },
      {
        sourceColumn: 0,
        role: 'annotation',
        type: 'string',
        calc: 'stringify',
      },
    ],
    ['Lost', 8.94, '#b87333', null],
    ['Broken', 10.49, 'silver', null],
    ['Depreciated', 19.3, 'gold', null],
    ['In Use', 21.45, 'color: #e5e4e2', null],
  ];

  const options = {
    title: 'Asset Status Summary',
    width: 600,
    height: 400,
    bar: { groupWidth: '95%' },
    legend: { position: 'none' },
  };

  return (
    <div className='w-100'>
      <Chart
        chartType="BarChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    </div>
  );
};

export default AssetsTypeChart;
