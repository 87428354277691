import { Button, TextField } from '@mui/material';
import React from 'react'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { Carousel, Col, Row, Table } from 'react-bootstrap';
import Swal from 'sweetalert2'
import { format, parse } from 'date-fns';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import moment from 'moment/moment';

const BookingDetails = (props) => {

  const [isLoading, setLoading] = React.useState(false);
  const [isFetching, setIsfetching] = React.useState(false);
  const [selectedRoom, setSelectedRoom] = React.useState();
  const [successMsg, setSuccessMsg] = React.useState();

  const success = () => {
    Swal.fire(
      'Success!',
      { successMsg },
      'success'
    )
  }


  const GetRoom = (id) => {
    setIsfetching(true)

    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/Conference/GetBookingById?id=${id}`)
      .then((response) => {
        setIsfetching(false)
        setSelectedRoom(response.data.$values)
        console.log(response.data.$values);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const CancelBooking = (id) => {
    setLoading(true)


    axios
      .post(`https://api-dhejomel.azgard.co.ke/api/Conference/CancelBooking?id=${id}`)
      .then((response) => {
        setLoading(false)
        setSuccessMsg("Reservation Cancelled Successfully!")
        success()
        props.onHide()
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const ConfirmPayment = (id) => {
    setLoading(true)
    axios
      .post(`https://api-dhejomel.azgard.co.ke/api/Conference/ConfirmPayment?id=${id}`)
      .then((response) => {
        setLoading(false)
        setSuccessMsg("Payment Processed Successfully!")
        success()
        props.onHide()
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    setLoading(false)
    GetRoom(props.rowId)
  }, [props.show])



  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
            Booking Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            isFetching ? <>
              <div className="d-flex w-100">
                <p>loading..</p>
              </div>
            </> :
              <Table striped bordered hover variant="light">
                <tbody>
                  <tr >
                    <td><strong>Full Name</strong></td>
                    <td colSpan={3}>{selectedRoom?.fullName}</td>
                  </tr><tr >
                    <td><strong>Phone Number</strong></td>
                    <td colSpan={3}>{selectedRoom?.phoneNumber}</td>
                  </tr><tr >
                    <td><strong>Email</strong></td>
                    <td colSpan={3}>{selectedRoom?.email}</td>
                  </tr><tr >
                    <td><strong>No. of Guests</strong></td>
                    <td colSpan={3}>{selectedRoom?.numberOfGuest}</td>
                  </tr><tr >
                    <td><strong>Extra Service</strong></td>
                    <td colSpan={3}>{selectedRoom?.extraServices
                    }</td>
                  </tr><tr >
                    <td><strong>Start Date</strong></td>
                    <td colSpan={3}>{moment(selectedRoom?.startDate).format('ll')}</td>
                  </tr><tr >
                    <td><strong>End Date</strong></td>
                    <td colSpan={3}>{moment(selectedRoom?.endDate).format('ll')}</td>
                  </tr>
                  <tr >
                    <td><strong>Payment Option</strong></td>
                    <td colSpan={3}>{selectedRoom?.paymentOption}</td>
                  </tr>
                  <tr >
                    <td><strong>Payment Status</strong></td>
                    <td colSpan={3}>{selectedRoom?.paymentStatus ? "Paid" : "Not Paid"}</td>
                  </tr>
                </tbody>
              </Table>
          }

        </Modal.Body>
        <Modal.Footer className='w-100 '>
          <>
            {
              isLoading ?
                <>
                  <LoadingButton
                    color="secondary"
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled
                  >
                    <span>Processing..</span>
                  </LoadingButton>
                </>
                :
                <div className="d-flex w-100 justify-content-between">
                  <Button
                    variant="contained"
                    className=' py-2 bg-danger'
                    onClick={() => CancelBooking(props?.rowId)}
                  >Cancel Booking
                  </Button>
                  {
                    selectedRoom?.paymentStatus ?
                      <Button
                        variant="contained"
                        className='py-2  '
                        disabled={selectedRoom?.paymentStatus}

                      >Service Paid
                      </Button>
                      :
                      <Button
                        variant="contained"
                        className='py-2  bg-success'
                        disabled={selectedRoom?.paymentStatus}
                        onClick={() => ConfirmPayment(props.rowId)}
                      >Confirm Payment
                      </Button>
                  }
                </div>
            }
          </>

        </Modal.Footer>

      </Modal>
    </div>
  )
}

export default BookingDetails

//1 VIP
//10 Executive
//2 Superior

//No. of people expected
//special