import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Table } from 'react-bootstrap';
import { Button } from '@mui/material';
import RoomManagerModel from '../RoomManagement/RoomManagement'
import axios from 'axios';
import EditPrices from '../RoomManagement/EditPrices';
import EditImages from '../RoomManagement/EditImages';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function RoomManagement() {
  const [value, setValue] = React.useState();
  const [editPrice, setEditprice] = React.useState(false);
  const [editImages, setEditImages] = React.useState(false);
  const [showRoomManager, setShowRoomManager] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [theRooms, setTheRooms] = React.useState([])
  const [roomDetails, setRoomDetails] = React.useState([])
  const [selectedId, setSelectedId] = React.useState()
  const [showEditPrice, setShowEditPrice] = React.useState(false)
  const [showEditImages, setShowEditImages] = React.useState(false)

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    GetRoomById(selectedId)
  };

  const GetRooms = () => {
    setIsLoading(true)
    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/RoomService/GetRooms`)
      .then((response) => {
        setTheRooms(response.data.$values)
        console.log(response.data.$values[0].id);

        setValue(response.data.$values[0].id)
        setSelectedId(response.data.$values[0].id)
        GetRoomById(response.data[0].$values.id)

      }).catch((e) => {
        console.log(e.response?.message)
      });
  }



  const GetRoomById = (roomId) => {
    setIsLoading(true)
    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/RoomService/GetRoomById?id=${roomId}`)
      .then((response) => {
        setRoomDetails(response.data.$values)

        console.log(response.data);
      }).catch((e) => {
        console.log(e.response.message)
      });
  }


  React.useEffect(() => {
    GetRooms()
  }, [])

  return (
    <div style={{ width: '100%' }}>
      <>
        {
          theRooms.map((item) =>
            <div style={{ borderBottom: 1, borderColor: 'divider' }} className='d-none'>

              <Tabs
                value={value}
                onChange={() => {
                  setValue(item.id)
                  GetRoomById(item.id)
                }}
                aria-label="basic tabs example"

                key={item.id}>
                <Tab label={item.roomType} onClick={() => setValue(item.id)}  {...a11yProps(item.id)} />
              </Tabs>

            </div>
          )
        }
      </>
      <div style={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {theRooms.map((item) => (
            <Tab key={item.id} label={item.roomType} {...a11yProps(item.id)} onClickCapture={() => setSelectedId(item.id)} />
          ))}
        </Tabs>
      </div>
      {
        theRooms.map((item) =>
          <CustomTabPanel value={selectedId} index={item.id}>
            <Table className="table ">
              <tbody>
                {
                  roomDetails?.category?.map((item, key) =>
                    <tr key={key}>
                      <td>{item.categoryName}</td>
                      <td><span className="badge rounded-pill bg-warning text-dark">KES {item.categoryPrice}</span></td>
                    </tr>
                  )
                }

              </tbody>
            </Table>
          </CustomTabPanel>
        )
      }



      <div className="d-flex w-100 justify-content-between">
        <Button
          variant="contained"
          className='w-50 me-4'
          onClick={() => {
            setShowEditPrice(true)
          }}
        >Edit Prices</Button>
        <Button
          variant="contained"
          className='w-50 ms-4'
          onClick={() => {
            setShowEditImages(true)
          }}
        >
          Edit Images
        </Button>
      </div>

      <RoomManagerModel
        show={showRoomManager}
        onHide={() => {
          setShowRoomManager(false)
          setEditImages(false)
          setEditprice(false)
        }}
        editPrice={editPrice}
        editImages={editImages}
        roomId={selectedId}
      />

      <EditPrices
        show={showEditPrice}
        onHide={() => setShowEditPrice(false)}
        roomId={selectedId}
        getRooms={() => GetRooms()}
      />

      <EditImages
        show={showEditImages}
        onHide={() => setShowEditImages(false)}
        roomId={selectedId}
        getRooms={() => GetRooms()}
      />
    </div >
  )
}

export default RoomManagement