import React from 'react'
import { Container, Divider, Paper, Typography } from '@mui/material'
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const AddAsset = () => {
  const [assetName, setAssetName] = React.useState(null)
  const [tagNumber, setTagNumber] = React.useState(null)
  const [supplier, setSupplier] = React.useState(null)
  const [price, setPrice] = React.useState(null)
  const [deliveryDate, setDeliveryDate] = React.useState(null)
  const [status, setStatus] = React.useState(null)
  const [assignedTo, setAssignedTo] = React.useState(null)
  const [serialNumber, setSerialNumber] = React.useState(null)

  const AddAsset = (props) => {

    const payload = {
      "id": uuidv4(),
      "name": assetName,
      "tagNumber": tagNumber,
      "supplier": supplier,
      "price": parseInt(price),
      "deliveryDate": deliveryDate,
      "status": status,
      "assignedTo": assignedTo,
      "serialNumber": serialNumber
    }

    axios
      .post("https://api-dhejomel.azgard.co.ke/api/Assets/AddAsset", payload)
      .then((response) => {
        console.log(response.data);
        props.fetchAssets()
        props.showAssets()
      }).catch((e) => {
        console.log(e.response.message)
      });

  }

  return (
    <div className='h-100 my-4' style={{ height: '100vh' }}>
      <Paper elevation={0}>
        <div className="d-flex justify-content-center py-3">
          <Typography variant='h6'>
            Add Asset
          </Typography>
        </div>

      </Paper>

      <Paper elevation={0} className='mt-3'>

        <div className="d-flex p-4">
          <div className="asset-details w-50 me-3">
            <Typography variant='subtitle1'>
              Add Details
            </Typography>
            <div >
              <TextField
                id="outlined-basic"
                label="Asset Name"
                variant="outlined"
                className='my-2 w-100 '
                onChange={(e) => setAssetName(e.target.value)}
              />

              <TextField
                id="outlined-basic"
                label="Tag Number"
                variant="outlined"
                className='my-2 w-100'
                onChange={(e) => setTagNumber(e.target.value)}
              />

              <TextField
                id="outlined-basic"
                label="Supplier"
                variant="outlined"
                className='my-2 w-100'
                onChange={(e) => setSupplier(e.target.value)}
              />

              <TextField
                id="outlined-basic"
                label="Price"
                variant="outlined"
                className='my-2 w-100'
                onChange={(e) => setPrice(e.target.value)}
              />

              <TextField
                id="outlined-basic"
                label="Serial Number"
                variant="outlined"
                className='my-2 w-100'
                onChange={(e) => setSerialNumber(e.target.value)}
              />

              <TextField
                id="outlined-basic"
                label="Assigned To"
                variant="outlined"
                className='my-2 w-100'
                onChange={(e) => setAssignedTo(e.target.value)}
              />

              <TextField
                id="outlined-basic"
                label="Status"
                variant="outlined"
                className='my-2 w-100'
                onChange={(e) => setStatus(e.target.value)}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Delivery Date"
                    className='w-100'
                    onChange={(e) => setDeliveryDate(e)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
          <div className="uploads w-50 ms-3">
            <Typography variant='subtitle1'>
              Asset Documents
            </Typography>

            <div>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label> Warranty file</Form.Label>
                <Form.Control type="file" />
              </Form.Group>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label> Receipt file</Form.Label>
                <Form.Control type="file" />
              </Form.Group>
              <div className="mt-4">
                <Button
                  variant="contained"
                  className='w-100'
                  onClick={AddAsset}
                >Add Asset</Button>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  )
}

export default AddAsset