import React from 'react'
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import AddCardIcon from '@mui/icons-material/AddCard';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import axios from 'axios';
import ExpenseData from '../Expense/componentss/expenseData';
import SalesData from './components/salesData';
import AddRecord from './addRecord';

function Sales(props) {
  const [showAddExpense, setShowAddExpense] = React.useState(false)
  const [salesData, setSalesData] = React.useState([])

  const fetchSales = () => {

    axios
      .get("https://api-dhejomel.azgard.co.ke/api/Sales/GetAllSales")
      .then((response) => {
        console.log(response.data);
        setSalesData(response.data.$values)
      }).catch((e) => {
        console.log(e.response.message)
      });

  }


  React.useEffect(() => {
    fetchSales()
  }, [])


  return (
    <div className='mt-4'>
      <div className="d-flex w-100 justify-content-between align-items-center">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" onClick={()=> props.hideSales()}>
            Expense Summary
          </Link>
          {/* <Link
            underline="hover"
            color="inherit"
            href="/material-ui/getting-started/installation/"
          >
            Core
          </Link> */}
          <Typography color="text.primary">{showAddExpense ? "Add Record" : "Sales Records"}</Typography>
        </Breadcrumbs>
        <Button variant="outlined" startIcon={<AddCardIcon />} className='mb-3' onClick={() => setShowAddExpense(true)}>
          Add Record
        </Button>
      </div>
      {
        showAddExpense ?
          <AddRecord
            showExpense={setShowAddExpense}
            fetchExpense={() => fetchSales()}
          /> :
          <SalesData data={salesData} />
      }


    </div>
  )
}

export default Sales