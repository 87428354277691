import React from 'react'
import { Container, Divider, Paper, Typography, Button, } from '@mui/material'
import AssetsNumberCharts from './components/charts/assetsNumberChart'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Assets from './assets';
import AssetData from './components/assetData';
import axios from "axios";
import AssetsExpenseChart from './components/charts/assetsExpenseChart';
import AssetStaffAssignment from './components/charts/assetStaffAssignment';
import AssetsTypeChart from './components/charts/assetsTypeChart';
import BackEndheader from '../../components/partials/backHeader';
import AssetsTable from './components/tables/assetsTable';

const AssetsDashboard = () => {
  const [allAssets, setAllAssets] = React.useState([]);

  const fetchAssets = () => {

    axios
      .get("https://api-dhejomel.azgard.co.ke/api/Assets/GetAllAssets")
      .then((response) => {

        setAllAssets(response.data.$values)
        console.log(response.data.$values);

      }).catch((e) => {
        console.log(e.response.message)
      });

  }

  React.useEffect(() => {
    fetchAssets()
  }, [])

  const sampleStats = [
    {
      category: 'Consumables',
      numbers: '50'
    }, {
      category: 'Furniture',
      numbers: '52'
    }, {
      category: 'Electronics',
      numbers: '28'
    }, {
      category: 'Motor Vehicles',
      numbers: '32'
    }, {
      category: 'Asset',
      numbers: '60'
    },
  ]
  return (
    <div style={{ backgroundColor: "whitesmoke" }}>
      <BackEndheader />
      <Container maxWidth="xl">
        <div className='d-flex mt-3 justify-content-between'>

          {
            sampleStats.map((item, key) =>
              <Paper
                elevation={0}
                style={{
                  height: "100px",
                  width: "235px",
                  backgroundColor: "#C4D7F2",
                  paddingBottom: '10px',
                  flexGrow: 1,
                  margin: "6px"
                }} className=''>
                <div className="px-4 pt-4">
                  <div className="d-flex align-items-center">
                    {/* <div className="assets-icon">
                    <AccessTimeFilledIcon style={} />
                  </div> */}
                    <div className="assets-stats">
                      <Typography variant='h6' gutterBottom className=''>
                        {
                          item.category
                        }
                      </Typography>
                      <Divider />
                      <Typography variant='h6' gutterBottom className='pb-3'>
                        {
                          item.numbers
                        }
                      </Typography>
                    </div>
                  </div>
                </div>

              </Paper>
            )
          }
        </div>

        <div className="asset-charts py-3">
          <div className="row">
            <div className="col-lg-6">
              <AssetsNumberCharts />

            </div>
            <div className="col-lg-6 bg-white">
              <AssetsExpenseChart />

            </div>
          </div>



        </div>

        <section className="assetsdistribution">
          <div className="row">
            <div className="col-lg-6">

              <AssetStaffAssignment />
            </div>
            <div className="col-lg-6 bg-white">
              <AssetsTypeChart />
            </div>
          </div>

        </section>
        <div className="mt-4 w-100 bg-white " style={{ marginBottom: '20px' }}>
          <AssetsTable />
        </div>
        <div className="mt-4">

        </div>
      </Container>
    </div>

  )
}

export default AssetsDashboard