import { Paper, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import AssignRoom from '../RoomManagement/assignRoom'
import axios from 'axios'
import Swal from 'sweetalert2'
import { InfinitySpin } from 'react-loader-spinner'

const Rooms = () => {
  const [showAssignRoom, setShowAssignRoom] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [updatedBookings, setUpdatedBookings] = React.useState([])
  const [updatedVIPBookings, setUpdatedVIPBookings] = React.useState([])
  const [updatedExecutiveBookings, setUpdatedExecutiveBookings] = React.useState([])
  const [updatedSuperiorBookings, setUpdatedSuperiorBookings] = React.useState([])
  const [selectedRoom, setSelectedRoom] = React.useState([])
  const [selectedRoomType, setSelectedRoomType] = React.useState(null)
  const bookings = [90, 101, 202, 303, 404, 505];
  const ExecutiveRooms = [90, 101, 102, 103, 104, 105, 106, 107];
  // const ExecutiveRooms = [90, 101, 202, 303, 404, 505];
  const VIPRooms = [10, 11,];
  const SuperiorRooms = [201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212];

  const fetchBookedRooms = () => {
    axios
      .get("https://api-dhejomel.azgard.co.ke/api/Booking/GetBookedRoomsByDate?date=2023-08-07&roomType=Executive")
      .then((response) => {
        setUpdatedBookings(updateBookingsWithStatus(bookings, response.data.$values))

      })
      .catch((e) => {
        console.log(e);
      });
  };

  const cancelRoomAssign = (roomId, roomType) => {
    axios
      .delete(`https://api-dhejomel.azgard.co.ke/api/Booking/CancelAssignedRoomBooking?roomId=${roomId}`)
      .then((response) => {
        Swal.fire(
          'Cancelled!',
          'Booking Cancelled, Room Vaccant!',
          'success'
        )
        fetchBookedRoomByDate("2023-08-07", roomType)
        // props.fetchBookedRooms()
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCancel = (item, roomType) => {
    Swal.fire({
      title: 'Do you want to cancel the Booking?',
      text: "The room will be set vaccant!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel Booking!'
    }).then((result) => {
      if (result.isConfirmed) {
        cancelRoomAssign(item.id, roomType)
      }
    })
  }

  const handleAssignRoom = (item, selectedRoom) => {
    console.log("item clicked!", item);
    setShowAssignRoom(true)
    setSelectedRoom(item)
    setSelectedRoomType(selectedRoom)
  }

  const fetchBookedRoomByDate = (date, roomType) => {
    setIsLoading(true)
    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/Booking/GetBookedRoomsByDate?date=${date}&roomType=${roomType}`)
      .then((response) => {

        switch (roomType) {
          case "VIP":
            setUpdatedVIPBookings(updateBookingsWithStatus(VIPRooms, response.data.$values))
            break;
          case "Executive":
            setUpdatedExecutiveBookings(updateBookingsWithStatus(ExecutiveRooms, response.data.$values))
            break;
          case "Superior":
            setUpdatedSuperiorBookings(updateBookingsWithStatus(SuperiorRooms, response.data.$values))
            break;

          default:
            setUpdatedExecutiveBookings(updateBookingsWithStatus(ExecutiveRooms, response.data.$values))
            break;
        }

        // setUpdatedBookings(updateBookingsWithStatus(bookings, response.data))
      })
      .catch((e) => {
        console.log(e);
      });
  };


  function updateBookingsWithStatus(bookings, bookedrooms) {
    const updatedBookings = bookings.map(roomno => {
      const bookedRoom = bookedrooms.find(bookedroom => bookedroom.bookedRoomNumber === roomno);
      if (bookedRoom) {
        return {
          id: bookedRoom.id,
          bookedRoomNumber: bookedRoom.bookedRoomNumber,
          guestNames: bookedRoom.guestNames,
          numberOfGuests: bookedRoom.numberOfGuests,
          checkOutDate: bookedRoom.checkOutDate,
          bookedBy: bookedRoom.bookedBy,
          bookingId: bookedRoom.bookingId,
          isbooked: true,
        };
      } else {
        return {
          bookedRoomNumber: roomno,
          checkOutDate: null,
          name: null,
          isbooked: false,
        };
      }
    });
    setIsLoading(false)
    return updatedBookings;
  }

  React.useEffect(() => {
    fetchBookedRoomByDate("2023-08-07", "Executive")
    fetchBookedRoomByDate("2023-08-07", "VIP")
    fetchBookedRoomByDate("2023-08-07", "Superior")
    fetchBookedRooms()
  }, [])

  return (
    <div className='p-2'>
      {
        isLoading ?
          <div style={{ height: "65vh" }}
            className='d-flex align-items-center justify-content-center'>
            <InfinitySpin
              width='200'
              color="rgba(128, 0, 0, 0.8)"
            />
          </div>
          :
          <div className='container'>
            <Typography variant='heading1'>VIP Rooms</Typography>
            <br />
            <div className="d-flex flex-wrap">
              {
                updatedVIPBookings.map((item, key) =>
                  <Paper
                    elevation={3}
                    sx={{ width: "150px", minHeight: "120px", margin: "10px", cursor: "pointer" }}
                    className={item.isbooked === true ? `bg-danger text-white` : 'bg-info'}>
                    <div className="d-flex flex-column justify-content-between p-3 justify-content-center align-items-center"
                      onClick={item.isbooked === true ? () => handleCancel(item, "VIP") : () => handleAssignRoom(item, "VIP")}
                    >
                      <strong>
                        <h1 className={item.isbooked === true ? `text-white m-0 p-0` : `text-dark`}>
                          {
                            item.bookedRoomNumber
                          }
                        </h1>
                      </strong>
                      {
                        item.isbooked ?
                          <div className="d-flex flex-column">
                            <div className="text-center">
                              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                                Booked Till
                              </Typography>
                            </div>
                            <div className="text-center">
                              <Typography variant="caption" gutterBottom>
                                {moment(item.checkOutDate).format("ddd, MMMM Do")}
                              </Typography>
                            </div>
                          </div>
                          :
                          <Typography variant="caption" gutterBottom>
                            Room Vaccant
                          </Typography>
                      }
                    </div>
                  </Paper>
                )
              }
            </div>
            <Typography variant='subheading1'>Superior Rooms</Typography>
            <br />
            <div className="d-flex flex-wrap">
              {
                updatedSuperiorBookings.map((item, key) =>
                  <Paper
                    elevation={3}
                    sx={{ width: "150px", minHeight: "120px", margin: "10px", cursor: "pointer" }}
                    className={item.isbooked === true ? `bg-danger text-white` : 'bg-info'}>
                    <div className="d-flex flex-column justify-content-between p-3 justify-content-center align-items-center"
                      onClick={item.isbooked === true ? () => handleCancel(item, "Superior") : () => handleAssignRoom(item, "Superior")}
                    >
                      <strong>
                        <h1 className={item.isbooked === true ? `text-white m-0 p-0` : `text-dark`}>
                          {
                            item.bookedRoomNumber
                          }
                        </h1>
                      </strong>
                      {
                        item.isbooked ?
                          <div className="d-flex flex-column">
                            <div className="text-center">
                              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                                Booked Till
                              </Typography>
                            </div>
                            <Typography variant="caption" gutterBottom>
                              {moment(item.checkOutDate).format("ddd, MMMM Do")}
                            </Typography>
                          </div>
                          :
                          <Typography variant="caption" gutterBottom>
                            Room Vaccant
                          </Typography>
                      }
                    </div>
                  </Paper>
                )
              }
            </div>

            <Typography variant='subheading1'>Executive Rooms</Typography>
            <br />
            <div className="d-flex flex-wrap">
              {
                updatedExecutiveBookings.map((item, key) =>
                  <Paper
                    elevation={3}
                    sx={{ width: "150px", minHeight: "120px", margin: "10px", cursor: "pointer" }}
                    className={item.isbooked === true ? `bg-danger text-white` : 'bg-info'}>
                    <div className="d-flex flex-column justify-content-between p-3 justify-content-center align-items-center"
                      onClick={item.isbooked === true ? () => handleCancel(item, "Executive") : () => handleAssignRoom(item, "Executive")}
                    >
                      <strong>
                        <h1 className={item.isbooked === true ? `text-white m-0 p-0` : `text-dark`}>
                          {
                            item.bookedRoomNumber
                          }
                        </h1>
                      </strong>
                      {
                        item.isbooked ?
                          <div className="d-flex flex-column">
                            <div className="text-center">
                              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                                Booked Till
                              </Typography>
                            </div>
                            <Typography variant="caption" gutterBottom>
                              {moment(item.checkOutDate).format("ddd, MMMM Do")}
                            </Typography>
                          </div>
                          :
                          <Typography variant="caption" gutterBottom>
                            Room Vaccant
                          </Typography>
                      }
                    </div>
                  </Paper>
                )
              }
            </div>

            <AssignRoom
              show={showAssignRoom}
              onHide={() => setShowAssignRoom(false)}
              selectedRoom={selectedRoom}
              fetchBookedRooms={() => fetchBookedRoomByDate("2023-08-07", selectedRoomType)}
              selectedRoomType={selectedRoomType}
            />
          </div >
      }
    </div>
  )
}

export default Rooms