import React from 'react'
import BackEndheader from '../../components/partials/backHeader'
import { Container, Divider, Paper, Typography } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from "react-router-dom";
import ExpenseSummary from '../../components/Expense/summary';
import MonthlyExpense from '../../components/Expense/monthly';
import ExpensePieChart from '../../components/Expense/salesSummary';
import ExpenseTransactions from './transactions';
import axios from 'axios';
import moment from 'moment';
import Sales from '../Sales/sales';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import CurrencyFormat from 'react-currency-format';


const Expense = () => {
  const [showTransactions, setShowTransactions] = React.useState(false)
  const [showSales, setShowSales] = React.useState(false)
  const [expenseData, setExpenseData] = React.useState([])
  const [salesData, setSalesData] = React.useState([])

  const fetchExpense = () => {

    axios
      .get("https://api-dhejomel.azgard.co.ke/api/Expenses/GetAllExpenses?$orderby=Date%20desc&$skip=0&$top=10")
      .then((response) => {
        console.log(response.data);
        setExpenseData(response.data.$values)
      }).catch((e) => {
        console.log(e.response.message)
      });

  }

  const fetchSales = () => {

    axios
      .get("https://api-dhejomel.azgard.co.ke/api/Sales/GetAllSales?$orderby=Date desc&$skip=0&$top=10")
      .then((response) => {
        console.log(response.data);
        setSalesData(response.data.$values)
      }).catch((e) => {
        console.log(e.response.message)
      });

  }

  React.useEffect(() => {
    fetchExpense()
    fetchSales()
  }, [showSales])
  return (
    <div style={{ backgroundColor: "whitesmoke", minHeight: "100vh" }}>
      <BackEndheader />

      <>
        {
          showSales ?
            <Container maxWidth="xl">
              <Sales hideSales={() => setShowSales(false)} />
            </Container>
            :
            <Container maxWidth="xl">
              {
                showTransactions ? <ExpenseTransactions hideExpense={() => setShowTransactions(false)} /> :
                  <>
                    <div className="d-flex justify-content-between align-items-center">
                      <Typography variant='h4' gutterBottom className='mt-4'>
                        Expense Analytics
                      </Typography>
                      <Breadcrumbs aria-label="breadcrumb">
                        {/* <Link underline="hover" color="inherit" href="/">
                          Home
                        </Link> */}
                        <Link
                          underline="hover"
                          color="grey"
                          to="/finance"
                        >
                          <Typography color="text.primary">Revenue Analytics</Typography>
                        </Link>
                        <Typography color="text.primary">Expense</Typography>
                      </Breadcrumbs>
                    </div>
                    <Divider gutterBottom />
                    <div className='p-4'>
                      <div className="grid">
                        <div className="row">
                          <div className="col-12 col-md-8 p-4 bg-white">
                            <MonthlyExpense className="p-4" />
                          </div>
                          <div className="col-12 col-md-4">
                            <ExpensePieChart className="p-4" />
                          </div>
                        </div>
                        <div className="row my-4 d-flex">
                          <div className="col-12 col-md-8 bg-white">
                            <div className="d-flex justify-content-between">
                              <Typography variant='h6' gutterBottom className='mt-4'>
                                Latest Expense
                              </Typography>
                              <div
                                onClick={() => setShowTransactions(true)}
                                style={{ cursor: 'pointer' }}
                                className="d-flex align-items-center"
                              >
                                <Typography
                                  variant='subtitle2'
                                  gutterBottom
                                  className='mt-4 d-flex align-items-center'>
                                  View All <KeyboardDoubleArrowRightIcon />
                                </Typography>

                              </div>

                            </div>


                            <table class="table table-striped table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Description</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Category</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  expenseData.map((item, key) => (
                                    <tr key={key}>
                                      <th scope="row">{key + 1}</th>
                                      <td>{moment(item.date).format('lll')}</td>
                                      <td>{item.description.split(' ').slice(0, 6).join(' ')}...</td>
                                      <td>
                                        <CurrencyFormat
                                          value={item.amount}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          prefix={'Ksh '}
                                          renderText={value => <div>{value}</div>}
                                        />
                                      </td>
                                      <td>{item.category}</td>
                                    </tr>
                                  ))
                                }


                              </tbody>
                            </table>
                          </div>

                          <div className="col-12 col-md-4 ">
                            <ExpenseSummary />
                          </div>
                        </div>

                        <div className="row my-4">
                          <div className="col-12 col-md-8 bg-white">
                            <div className="d-flex justify-content-between">
                              <Typography variant='h6' gutterBottom className='mt-4'>
                                Daily Sales Record
                              </Typography>
                              <div onClick={() => setShowSales(true)} style={{ cursor: 'pointer' }}>
                                <Typography variant='subtitle2' gutterBottom className='mt-4'>
                                  View All  <KeyboardDoubleArrowRightIcon />
                                </Typography>
                              </div>

                            </div>


                            <table class="table table-striped table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Comment</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Category</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  salesData.map((item, key) =>
                                    <tr key={key}>
                                      <th scope="row">{key + 1}</th>
                                      <td>{moment(item.date).format('lll')}</td>
                                      <td>{item.comment.split(' ').slice(0, 6).join(' ')}...</td>
                                      <CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true} prefix={'Ksh '} renderText={value => <div>{value}</div>} />
                                      <td>{item.category}</td>
                                    </tr>
                                  )
                                }



                              </tbody>
                            </table>

                          </div>
                          <div className="col-12 col-md-4">
                            {/* <ExpenseSummary /> */}
                          </div>
                        </div>
                      </div>

                    </div>
                  </>
              }


            </Container>
        }
      </>

    </div>
  )
}

export default Expense