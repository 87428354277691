import { Button, Chip, Divider, Paper, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import { format, parse } from 'date-fns'
import moment from 'moment'
import React from 'react'
import { Table } from 'react-bootstrap'
import RoomManagement from './roomManagement'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import RoomManagerModel from '../RoomManagement/RoomManagement'
import AddUser from '../user/addUserModal'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Swal from 'sweetalert2'
import KingBedIcon from '@mui/icons-material/KingBed';

function Dashboard(props) {
  const [users, setUsers] = React.useState([])
  const [summary, setSummary] = React.useState(null)
  const [showRoomManager, setShowRoomManager] = React.useState(false)
  const [userShow, setUserShow] = React.useState(false);



  const getStatisticbyDate = () => {
    const dateObjectOne = new Date();
    const formattedDateOne = format(dateObjectOne, "yyyy-MM-dd");

    const payload = {
      theDate: formattedDateOne
    }

    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/Statistics/GetStatistics?date=${formattedDateOne}`,)
      .then((response) => {
        console.log(response);
        setSummary(response.data.$values)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUsers = () => {
    axios
      .get(`https://api-dhejomel.azgard.co.ke/api/Users/GetUsers`,)
      .then((response) => {
        setUsers(response.data.$values)
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteUser = (userId) => {
    axios
      .delete(`https://api-dhejomel.azgard.co.ke/api/Users/DeleteUser?userId=${userId}`,)
      .then((response) => {
        Swal.fire(
          'Success!',
          'User Added Successfully!',
          'success'
        )

        getUsers()
      })
      .catch((error) => {
        console.log(error);
      });
  };



  React.useEffect(() => {
    getStatisticbyDate()
    getUsers()

  }, [])

  return (
    <div className='px-4 mt-3 container'>
      <Typography variant="h4" component="h2">
        Welcome, {props?.loggedInUser.firstName}
      </Typography>
      <Typography variant="subtitle2" component="h2">
        Today, {moment(Date.now()).format('ll')}
      </Typography>
      <div className="d-flex  py-4 flex-grow-1">
        <Paper elevation={3} className='w-50 p-4 me-2'>
          <Typography variant="h5" component="h2">
            Conference Rooms
          </Typography>
          <Divider />
          <Chip label={`Booked - ${summary?.conferenceBooked} /1`} className='my-3 bg-success text-white' />
          <Chip label={`${summary?.conferenceGuests}- Guest`} className='my-3 bg-warning text-dark mx-2' />
        </Paper>
        <Paper elevation={3} className='w-50  p-4 ms-2'>
          <Typography variant="h5" component="h2">
            Hotel Rooms
          </Typography>
          <Table className="table">
            <tbody>
              <tr>
                <td>VIP</td>
                <td><span className="badge rounded-pill bg-warning text-dark">Booked - {summary?.vipBooked}/1</span></td>
              </tr>
              <tr>
                <td>Executive</td>
                <td><span className="badge rounded-pill bg-success">Booked - {summary?.executiveBooked}/8</span></td>
              </tr>
              <tr>
                <td>Superior</td>
                <td><span className="badge rounded-pill bg-info text-dark">Booked -{summary?.superiorBooked}/12</span></td>
              </tr>
            </tbody>
          </Table>
          <Divider />

        </Paper>


      </div>
    <div className='mb-5'>
    {
        props?.loggedInUser.role === "Administrator" && <div className="room-management w-100 d-flex">
          <Paper elevation={3} className='w-50  p-4 me-2'>
            <div className="d-flex w-100 justify-content-between pb-3">
              <Typography variant="h5" component="h2">
                Room Management
              </Typography>
              <Button
                variant="outlined"
                style={{
                  borderColor: "rgb(128, 0, 0,0.8)",
                  color: "rgb(128, 0, 0)"
                }}
                onClick={() => setShowRoomManager(true)}
              >
                <div className="d-flex align-items-center">
                  <div className="me-2">Add Room</div> <BorderColorIcon />
                </div>
              </Button>
            </div>
            <RoomManagement />

            <Divider />

          </Paper>

          <Paper elevation={3} className='w-50  p-4 ms-2'>
            <div className="d-flex w-100 justify-content-between pb-3">
              <Typography variant="h5" component="h2">
                User Management
              </Typography>
              <Button
                variant="outlined"
                style={{
                  borderColor: "rgb(128, 0, 0,0.8)",
                  color: "rgb(128, 0, 0)"
                }}
                onClick={() => setUserShow(true)}
              >
                <div className="d-flex align-items-center" >
                  <div className="me-2">Add User</div> <BorderColorIcon />
                </div>
              </Button>
            </div>
            <Table className="table">
              <tbody>
                {
                  users.map((item, key) =>
                    <tr key={key}>
                      <td>{item.firstName} {item.lastName}</td>
                      <td>-</td>
                      <td>
                        <strong><span >{item.role}</span></strong>
                      </td>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() => deleteUser(item.id)}
                      >
                        <DeleteForeverIcon style={{ color: "rgb(128, 0, 0)" }}

                        />
                      </td>
                    </tr>
                  )
                }



              </tbody>
            </Table>
            <Divider />

          </Paper>
        </div>
      }

    </div>

      <RoomManagerModel
        show={showRoomManager}
        onHide={() => setShowRoomManager(false)}
      />

      <AddUser
        show={userShow}
        onHide={() => {
          setUserShow(false)
        }}
        getUsers={() => getUsers()}
      />
    </div>
  )
}

export default Dashboard