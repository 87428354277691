import React from 'react'
import { Container, Divider, Paper, Typography, Button, } from '@mui/material'
import axios from 'axios';
import moment from 'moment';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';

const AssetsTable = () => {
  const [assetsData, setAssetsData] = React.useState([])
  const fetchAssets = () => {
    axios
      .get("https://api-dhejomel.azgard.co.ke/api/Assets/GetAllAssets?$skip=0&$top=10")
      .then((response) => {
        console.log(response.data);
        setAssetsData(response.data.$values)
      }).catch((e) => {
        console.log(e.response.message)
      });

  }

  React.useEffect(() => {
    fetchAssets()
  }, [])

  return (
    <div className='bg-white p-3 w-100 '>
      <div className="assets-header d-flex justify-content-between py-3">
        <Typography variant='subtitle2' className='ps-3'>
          Assets List
        </Typography>
        <div className="d-flex">
          <NavLink to="/all-assets">
            <Typography
              variant='subtitle2'
              gutterBottom
              className='mt-4 d-flex align-items-center'>
              View All <KeyboardDoubleArrowRightIcon />
            </Typography>
          </NavLink>

        </div>
      </div>
      <Divider />
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Tag Number</th>
            <th scope="col">Supplier</th>
            <th scope="col">Price</th>
            <th scope="col">Delivered on</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {
            assetsData.map((item, key) =>
              <tr className='rounded-2'>
                <th scope="row">{key + 1}</th>
                <td>{item.name}</td>
                <td>{item.tagNumber}</td>
                <td>{item.supplier}</td>
                <td>{item.price}</td>
                <td>{moment(item.deliveryDate).format('ll')}</td>
                <td>{item.status}</td>

              </tr>
            )
          }



        </tbody>
      </table>
    </div>
  )
}

export default AssetsTable