import React, { useState } from 'react'
import BackEndheader from '../../components/partials/backHeader'
import { Button, Divider, Container, } from '@mui/material'
import Typography from '@mui/material/Typography';
import AddAsset from './addAsset'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import axios from "axios";
import AssetData from './components/assetData';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import AssetsDashboard from './dashboard';
import Dashboard from '../../components/Dashboard/dashboard';

const Assets = () => {

  const [showAsset, setShowAsset] = useState(true);
  const [allAssets, setAllAssets] = useState([]);
  const [showDashboard, setShowDashboard] = useState(false);

  const handleShowAssets = () => setShowAsset(false);


  const fetchAssets = () => {

    axios
      .get("https://api-dhejomel.azgard.co.ke/api/Assets/GetAllAssets")
      .then((response) => {

        setAllAssets(response.data.$values)
        console.log(response.data.$values);

      }).catch((e) => {
        console.log(e.response.message)
      });

  }

  React.useEffect(() => {
    fetchAssets()
  }, [])


  return (
    <div>
      <BackEndheader />
      <>
        {
          showDashboard ? <AssetsDashboard /> :
            <div className="d-flex " style={{ backgroundColor: 'whitesmoke', minHeight: '90vh' }}>
              <Container maxWidth="xl">
                <div className='py-4 w-100'>
                  {
                    !showAsset && <>
                      <div onClick={() => setShowAsset(true)} style={{
                        cursor: 'pointer'
                      }}>
                        <Typography variant='subtitle1' ><ReplyAllIcon /> Back</Typography>
                      </div>
                      <Divider />
                    </>
                  }
                  <div className="d-flex justify-content-between mb-3 align-items-center">
                    {
                      showAsset &&
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                          Dashboard
                        </Link>
                        <Link
                          underline="hover"
                          color="inherit"
                          href="/material-ui/getting-started/installation/"
                        >
                          Assets
                        </Link>
                        <Typography color="text.primary">All Assets</Typography>
                      </Breadcrumbs>
                    }

                    <>
                      {
                        showAsset && <Button variant="outlined" startIcon={<CreateNewFolderIcon />} onClick={() => setShowAsset(false)}>
                          Add Asset
                        </Button>
                      }
                    </>
                  </div>
                  <>
                    {/* <AssetsDashboard /> */}
                    {
                      showAsset ?
                        <AssetData data={allAssets} />
                        :
                        <AddAsset
                          fetchAssets={fetchAssets}
                          showAssets={handleShowAssets}
                        />
                    }
                  </>


                </div>
              </Container>
            </div>
        }
      </>

    </div>
  )
}

export default Assets