import React from 'react'
import { Container, Divider, Paper, Typography } from '@mui/material'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';

const AddRecord = (props) => {
  const [amount, setAmount] = React.useState(null)
  const [transactionDate, setTransactionDate] = React.useState(null)
  const [description, setDescription] = React.useState(null)
  const [category, setCategory] = React.useState(null);

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const submitSales = () => {
    const payload = {
      category: category,
      amount: amount,
      comment: description,
      date: transactionDate
    }
    // "2023-12-16T20:59:01.536Z"
    axios
      .post("https://api-dhejomel.azgard.co.ke/api/Sales/AddSale", payload)
      .then((response) => {
        console.log(response.data);
        props.showExpense()
        props.fetchExpense()
      }).catch((e) => {
        console.log(e.response.message)
      });

  }

  return (
    <div className='bg-white p-4'>
      <div className="d-flex align-items-center">
        <div className="back" onClick={() => props.showExpense()} style={{ cursor: 'pointer' }}>
          <ReplyAllIcon className='me-2' />
        </div>
        <Typography variant="h6" >
          Add Sales Record
        </Typography>
      </div>
      <Divider />
      <div className="mt-3">
        <div className="d-flex justify-content-between">
          <div className="w-50">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Category"
                onChange={handleChange}
              >
                <MenuItem value="Restaurant">Restaurant</MenuItem>
                <MenuItem value="Bar">Bar</MenuItem>
                <MenuItem value="Rooms">Rooms</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>

            <TextField
              id="outlined-basic"
              label="Amount"
              variant="outlined"
              className='w-100 my-4'
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />


            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  label="Transaction Date"
                  className='w-100 '
                  value={transactionDate}
                  onChange={(e) => setTransactionDate(e)}
                />
              </DemoContainer>
            </LocalizationProvider>

          </div>
          <div className="w-50 ms-4">
            <TextField
              id="outlined-multiline-static"
              label="Comment"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={8}
              defaultValue="Default Value"
              className='w-100'
            />
          </div>
        </div>

        <div className="d-flex w-100 my-4 align-items-center justify-content-center">
          <Button
            variant="contained"
            className='w-100'
            onClick={submitSales}
          >Add Sales
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AddRecord